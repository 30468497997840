import axios from "axios"
export default {
  state: {
    list: [],
  },
  mutations: {
    SET_REFERENCE_BOOK(state, payload) {
      state.list = payload
    },
  },
  actions: {
    getReferenceBook({ commit }) {
      return axios
        .get("/api/panel/options/getAllHl")
        .then((res) => {
          commit("SET_REFERENCE_BOOK", res.data.highloads)
          return res.data.highloads
        })
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    deleteReferenceBookById(_, id) {
      return axios
        .delete(`/api/panel/options/deleteHlById/${id}`)
        .then((res) => res.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    addReferenceBook(_, payload) {
      return axios
        .post("/api/panel/options/addHighloadModel", payload)
        .then((res) => res.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    getReferenceBookById(_, id) {
      return axios
        .get(`/api/panel/options/getHlById/${id}`)
        .then((res) => res.data.highload)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    deleteReferenceBookValueById(_, id) {
      return axios
        .delete(`/api/panel/options/deleteHlValue/${id}`)
        .then((res) => res.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    addReferenceBookValue(_, payload) {
      return axios
        .post(`/api/panel/options/addHlValue`, payload)
        .then((res) => res.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    updateReferenceBookValue(_, { id, data }) {
      return axios
        .post(`/api/panel/options/updateHlValue/${id}`, data)
        .then((res) => res.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    updateReferenceBookById(_, { id, data }) {
      return axios
        .post(`/api/panel/options/updateHlById/${id}`, data)
        .then((res) => res.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },
  },
}
