import Vue from "vue"

export default {
  SET_USER(state, payload) {
    state.isAuthenticated = true
    state.token = payload.token
    Vue.set(state, "user", payload.user)
    if (payload.merchant) {
      Vue.set(state, "merchant", payload.merchant)
    }
    localStorage.setItem("token", payload.token)
  },
  LOGOUT(state) {
    state.isAuthenticated = false
    state.token = state.user = state.merchant = null
    localStorage.removeItem("token")
  },
  SET_TOKEN(state, token) {
    state.token = token
  },
  SET_PROCESSING(state, payload) {
    state.processing = payload
  },
  SET_BASE_URL(state, payload) {
    state.imagehost = payload
  },
  SET_ERROR_MESSAGE(state, payload) {
    state.error_message = payload
  },
  SET_INFO_MESSAGE(state, payload) {
    state.info_message = payload
  },
  SET_UPDATE_QUESTION(state, payload) {
    state.update_question = payload
  },
  SET_MENU(state, payload) {
    state.menu = payload
  },
  SET_MIN_SIDEBAR(state) {
    state.miniVariant = !state.miniVariant
  },
  SET_COUNTRY(state, payload) {
    state.country = payload
  },
  SET_CURRENCY(state, payload) {
    state.currency = payload
  },
  SET_USERS(state, payload) {
    state.users = payload
  },
  SET_NOTICES(state, { appeals_count, orders_count, bugs_count }) {
    state.notices.appeals_count = appeals_count
    state.notices.orders_count = orders_count
    state.notices.bugs_count = bugs_count
  },
}
