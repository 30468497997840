<template>
  <v-app>
    <template v-if="!isMobile">
      <router-view name="sidebar" />
      <v-main class="vuse-content">
        <v-fade-transition>
          <router-view />
        </v-fade-transition>
      </v-main>
    </template>
    <v-row v-else>
      <v-col align-self="center" class="text-center">
        <h1>Мобильная версия в разработке!</h1>
      </v-col>
    </v-row>
    <v-overlay :value="processing"></v-overlay>
    <hexagon v-if="processing" />
  </v-app>
</template>

<script>
import { mapState } from "vuex"
import Hexagon from "~/components/Hexagon"
export default {
  components: {
    Hexagon,
  },
  data: () => {
    return {
      settingsDrawer: false,
      isMobile: false,
    }
  },
  computed: {
    ...mapState(["processing"]),
    error_message: {
      get() {
        return this.$store.state.error_message
      },
      set(val) {
        this.$store.commit("SET_ERROR_MESSAGE", val)
      },
    },
    info_message: {
      get() {
        return this.$store.state.info_message
      },
      set(val) {
        this.$store.commit("SET_INFO_MESSAGE", val)
      },
    },
    update_question: {
      get() {
        return this.$store.state.update_question
      },
      set(val) {
        this.$store.commit("SET_UPDATE_QUESTION", val)
      },
    },
  },
  watch: {
    error_message: function (val) {
      if (val) {
        this.errorAlert(val)
        this.$store.commit("SET_PROCESSING", false)
        this.error_message = null
      }
    },
    info_message: function (val) {
      if (val) {
        this.infoMessagesConfirm(val)
        this.info_message = null
      }
    },
    update_question: function (val) {
      if (val) {
        this.update_question = false
        this.showMessageUpdate()
      }
    },
  },
  beforeDestroy() {
    if (typeof window === "undefined") return
    window.removeEventListener("resize", this.onResize, { passive: true })
  },
  beforeCreate() {
    document.documentElement.setAttribute("lang", "ru")
  },
  mounted() {
    this.onResize()
    window.addEventListener("resize", this.onResize, { passive: true })
  },
  methods: {
    onResize() {
      //this.isMobile = window.innerWidth < 1024
    },
    async showMessageUpdate() {
      const choice = await this.infoMessagesConfirm(
        `Административный кабинет обновлен до версии ${this.$projectVersion}`,
        `Перейти для ознакомления?`,
        true,
      )
      if (choice.isConfirmed) {
        this.$router.push({ name: "HistoryChanges" })
      }
    },
  },
}
</script>
