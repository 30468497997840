import Vue from "vue"
import format from "date-fns/format"
import upperFirst from "lodash/upperFirst"
import camelCase from "lodash/camelCase"
import { timeZero } from "~/utils/helpers"

const requireComponent = require.context("../components/Stock", false, /Vuse[A-Z]\w+\.(vue|js)$/)

requireComponent.keys().forEach((fileName) => {
  const componentConfig = requireComponent(fileName)

  const componentName = upperFirst(
    camelCase(
      fileName
        .split("/")
        .pop()
        .replace(/\.\w+$/, ""),
    ),
  )

  Vue.component(componentName, componentConfig.default || componentConfig)
})

Vue.filter("twoDigits", (value) => {
  if (value.toString().length <= 1) {
    return "0" + value.toString()
  }
  return value.toString()
})

Vue.filter("firstChar", function (str) {
  return str.charAt(0).toUpperCase()
})
Vue.filter("first2Char", function (str) {
  return str !== undefined && str.substring(0, 2).toUpperCase()
})

Vue.filter("fulldate", function (value) {
  return format(value, "MMM dd, yyyy HH:mm")
})
Vue.filter("date", function (value) {
  return format(value, "MMM dd, yyyy")
})

Vue.filter("formatDate", function (value) {
  if (!value) return ""
  return new Date(value).toLocaleString().split(",")[0]
})

Vue.filter("formatPrice", function (value, currency = "RUB") {
  if (!value) return ""
  return Number(value).toLocaleString("ru-RU", {
    style: "currency",
    currency,
    minimumFractionDigits: 2,
  })
})

// Дата вида  "03.11.2021, 15:02:23"
Vue.filter("formatDateTime", function (value) {
  if (!value) return ""
  return new Date(value).toLocaleString()
})

Vue.filter("formatDateShort", function (value) {
  if (!value) return ""
  let data = new Date(value)
  return (
    timeZero(timeZero(data.getDate()) + "." + timeZero(data.getMonth()) + ", " + data.getHours()) +
    ":" +
    timeZero(data.getMinutes())
  )
})

// Дата вида "3 дек 21"
Vue.filter("formatDateShortMonth", function (value) {
  if (!value) return ""
  let data = new Date(value)
  return (
    data.getDate() +
    " " +
    data.toLocaleDateString("ru-RU", { month: "short" }).replace(/\./g, "") +
    " " +
    data.toLocaleDateString("ru-RU", { year: "2-digit" }).replace(/[г.]/gi, "")
  )
})

// Дата вида "8 марта 2021г."
Vue.filter("formatDateLongMonth", function (value) {
  if (!value) return ""
  let data = new Date(value)
  let monthA =
    "января,февраля,марта,апреля,мая,июня,июля,августа,сентября,октября,ноября,декабря".split(",")
  return (
    data.getDate() +
    " " +
    monthA[data.getMonth()] +
    " " +
    data.toLocaleDateString("ru-RU", { year: "numeric" }) +
    "г."
  )
})

// Дата вида "8 марта 2021г. 18:34"
Vue.filter("formatDateLongMonthIncTime", function (value) {
  if (!value) return ""
  let data = new Date(value)
  let monthA =
    "января,февраля,марта,апреля,мая,июня,июля,августа,сентября,октября,ноября,декабря".split(",")
  return (
    data.getDate() +
    " " +
    monthA[data.getMonth()] +
    " " +
    data.toLocaleDateString("ru-RU", { year: "numeric" }) +
    "г, " +
    timeZero(data.getHours()) +
    ":" +
    timeZero(data.getMinutes())
  )
})

Vue.filter("formatDateShortMonthIncTime", function (value) {
  if (!value) return ""
  let data = new Date(value)
  return (
    data.getDate() +
    " " +
    data.toLocaleDateString("ru-RU", { month: "short" }).replace(/\./g, "") +
    " " +
    data.toLocaleDateString("ru-RU", { year: "2-digit" }).replace(/[г.]/gi, "") +
    ", " +
    timeZero(data.getHours()) +
    ":" +
    timeZero(data.getMinutes())
  )
})

Vue.filter("formatDateShortMonthIncTimeAndSec", function (value) {
  if (!value) return ""
  let data = new Date(value)
  return (
    data.getDate() +
    " " +
    data.toLocaleDateString("ru-RU", { month: "short" }).replace(/\./g, "") +
    " " +
    data.toLocaleDateString("ru-RU", { year: "2-digit" }).replace(/[г.]/gi, "") +
    ", " +
    timeZero(data.getHours()) +
    ":" +
    timeZero(data.getMinutes()) +
    ":" +
    timeZero(data.getSeconds())
  )
})

Vue.filter("formatURL", function (value) {
  if (!value) return ""
  return value.replace(/(^\w+:|^)\/\//, "")
})
