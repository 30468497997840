<template>
  <v-dialog v-model="dialog" max-width="800">
    <v-card>
      <v-card-title class="text-h5"> В системе уже есть товар с таким именем </v-card-title>

      <v-card-text v-if="info && info.master">
        <v-list-item v-for="item in info.master" :key="item.id" two-line>
          <v-list-item-content>
            <div class="text-overline">
              <a
                :href="`https://admin.bh.market/${
                  item.product_type == 1 ? 'group-products' : 'master-products'
                }/${item.id}`"
                target="_blank"
                >{{ item.id }} - {{ item.name }}</a
              >
            </div>
          </v-list-item-content>

          <v-list-item-avatar tile size="100" color="grey">
            <v-image
              :src="`/storage/cloud/master_products/${item.id}/${item.image}`"
              width="100"
              height="100"
            />
          </v-list-item-avatar>
        </v-list-item>
        <table id="copyTable" style="height: 0">
          <tr v-for="(products, k) in info.products" :key="k">
            <td>
              <a :href="`https://admin.bh.market/products/${products}`" target="_blank">{{
                products
              }}</a>
            </td>
            <td v-if="info.master">
              <a
                :href="`https://admin.bh.market/${
                  info.master[0].product_type == 1 ? 'group-products' : 'master-products'
                }/${info.master[0].id}`"
                target="_blank"
                >{{ info.master[0].name }}</a
              >
            </td>
            <td>{{ cat }}</td>
            <td>{{ info.categories }}</td>
          </tr>
        </table>
      </v-card-text>

      <v-card-actions>
        <v-btn @click="copy"> Копировать ошибку </v-btn>
        <v-spacer />
        <v-btn text @click="dialog = false"> Закрыть </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex"
export default {
  props: {
    info: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    dialog: false,
  }),
  computed: {
    ...mapState({
      allCategories: (state) => state.categories.categories.allCategories,
    }),
    cat() {
      if (this.info.categories) {
        let cat = this.allCategories.find((c) => c.id == this.info.categories)
        return cat.name
      }
      return ""
    },
  },
  methods: {
    copy() {
      var range = document.createRange()
      let containerNode = document.getElementById("copyTable")
      range.selectNode(containerNode)
      window.getSelection().removeAllRanges()
      window.getSelection().addRange(range)
      document.execCommand("copy")
      window.getSelection().removeAllRanges()
      this.dialog = false
    },
  },
}
</script>

<style lang="sass" scoped>
table
    width: 100%
    border: 1px solid
    td
        padding: 3px
        border: 1px solid #ddd
</style>
