<template>
  <span @mouseover="onHover" @mouseleave="offHover">
    <slot name="left" />
    {{ message && isVisibleMessage ? message : hiddeString }}
    <slot name="right" />
  </span>
</template>

<script>
export default {
  name: "HiddenValue",
  props: {
    value: {
      type: String,
      default: null,
    },
    count: {
      type: Number,
      default: null,
    },
    min: {
      type: Number,
      default: 8,
    },
    max: {
      type: Number,
      default: 25,
    },
    message: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    isVisibleMessage: false,
  }),
  computed: {
    hiddeString() {
      if (this.value) return this.value.replace(/\S/g, "*")

      if (this.count) return Array(this.count).fill("*").join("")

      const randomCounter = Math.floor(Math.random() * (this.max - this.min) + this.min)
      return Array(randomCounter).fill("*")
    },
  },
  methods: {
    onHover() {
      this.isVisibleMessage = true
    },
    offHover() {
      this.isVisibleMessage = false
    },
  },
}
</script>
