import axios from "axios"
export default {
  state: {},
  mutations: {},
  getters: {},
  actions: {
    addMasterProductParsing(_, payload) {
      return axios
        .post("/api/panel/masterProductParsing/addMasterProductParsing", payload)
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    listMasterProductParsing(_, payload) {
      const params = new URLSearchParams(payload)
      return axios
        .get("/api/panel/masterProductParsing/getMasterProductParsing", { params })
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    editMasterProductParsing(_, payload) {
      return axios
        .post("/api/panel/masterProductParsing/editMasterProductParsing", payload)
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    deleteMasterProductParsing(_, payload) {
      return axios
        .post("/api/panel/masterProductParsing/deleteMasterProductParsing", payload)
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    parsingMasterProduct(_, payload) {
      return axios
        .post("/api/panel/masterProductParsing/masterProductPars", payload)
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    updateMasterProductParsing(_, payload) {
      return axios
        .post("/api/panel/masterProductParsing/updateMasterProductsByParsing", payload)
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    getMasterProductInfo(_, payload) {
      const params = new URLSearchParams(payload)
      return axios
        .get("/api/panel/parser/getMasterProductInfo", { params })
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },
  },
}
