import axios from "axios"
export default {
  state: {
    type: {
      1: "yml",
      2: "excel",
      3: "1C",
      4: "manual",
    },
  },
  mutations: {},
  actions: {
    importStart(ctx, payload) {
      return new Promise((resolve) => {
        axios.post("/api/importStart", payload).then((response) => {
          resolve(response.data)
        })
      })
    },

    importStartCheck(ctx, payload) {
      return new Promise((resolve) => {
        axios.post("/api/importStartCheck", payload).then((response) => {
          resolve(response.data)
        })
      })
    },

    importStartFile(ctx, payload) {
      const config = { "content-type": "multipart/form-data" }
      return new Promise((resolve) => {
        axios.post("/api/importStart", payload, config).then((response) => {
          resolve(response.data)
        })
      })
    },

    importEnd(ctx, payload) {
      return new Promise((resolve) => {
        axios.post("/api/importEnd", payload).then((response) => {
          resolve(response.data)
        })
      })
    },

    list(ctx, payload) {
      return new Promise((resolve) => {
        axios.post("/api/panel/import/list", payload).then((response) => {
          resolve(response.data)
        })
      })
    },
  },
}
