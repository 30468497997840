import { VTreeview } from "!/node_modules/vuetify/lib"
import {
  filterTreeItems,
  filterTreeItem,
} from "!/node_modules/vuetify/lib/components/VTreeview/util/filterTreeItems"
import debounce from "lodash/debounce"

export default VTreeview.extend({
  name: "VTreeviewExtended",

  props: {
    openAll: Boolean,
    loading: Boolean,
  },
  computed: {
    excludedItems() {
      const excluded = new Set()
      if (!this.search) return excluded

      for (let i = 0; i < this.items.length; i++) {
        filterTreeItems(
          this.filter || filterTreeItem,
          this.items[i],
          this.search,
          this.itemKey,
          this.itemText,
          this.itemChildren,
          excluded,
        )
      }
      return excluded
    },
  },
  watch: {
    openAll(value) {
      this.updateAll(value)
    },

    search(value) {
      this.updateAll(false)
      if (value) this.setSearch(value)
    },
  },

  methods: {
    setSearch: debounce(function (value) {
      value && value.length >= 3 && this.updateAll(true)
    }, 500),
  },
})
