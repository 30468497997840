Promise.allValues = async function (array) {
  const response = await Promise.all(array)
  const result = {}
  for (let value of Object.values(response)) {
    let key = Object.keys(value).toString()
    if (value[key] instanceof Promise) {
      result[key] = await value[key]
    } else {
      result[key] = value[key]
    }
  }
  return Promise.resolve(result)
}
