import axios from "axios"

export default {
  state: {},
  getters: {},
  mutations: {},

  actions: {
    getDeliveryBook() {
      return axios.get("/api/panel/deliveryBook/get").then((response) => {
        return response.data
      })
    },
    getDeliveryBookAll() {
      return axios.get("/api/panel/deliveryBook/get?all=1").then((response) => {
        return response.data
      })
    },

    addDeliveryBook(_, payload) {
      const config = { "content-type": "multipart/form-data" }
      const formData = new FormData()
      formData.append("is_active", 0)
      for (let key in payload) {
        formData.append(key, payload[key])
      }

      return axios.post("/api/panel/deliveryBook/add", formData, config).then((response) => {
        return response.data
      })
    },

    editDeliveryBook(_, payload) {
      const config = { "content-type": "multipart/form-data" }
      const formData = new FormData()
      for (let key in payload) {
        formData.append(key, payload[key])
      }
      return axios.post("/api/panel/deliveryBook/edit", formData, config).then((response) => {
        return response.data
      })
    },

    deleteDeliveryBook(_, id) {
      return axios.post("/api/panel/deliveryBook/delete", id).then((response) => {
        return response.data
      })
    },
  },
}
