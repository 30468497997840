import { URL_MULTISEARCH, URL_SEARCHBOOSTER } from "~/utils/server_urls"
export default {
  state: {},
  mutations: {},
  actions: {
    async multisearch(_, payload) {
      const url = new URL(URL_MULTISEARCH)
      for (const [key, value] of Object.entries(payload)) {
        url.searchParams.append(key, value)
      }
      return fetch(url)
        .then((response) => response.json())
        .then((response) => response)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },
    async searchbooster(_, payload) {
      const url = new URL(URL_SEARCHBOOSTER)
      for (const [key, value] of Object.entries(payload)) {
        url.searchParams.append(key, value)
      }
      return fetch(url)
        .then((response) => response.json())
        .then((response) => response)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },
  },
  getters: {},
}
