<template>
  <v-sheet
    :height="height"
    :max-height="maxHeight"
    :width="width"
    :max-width="maxWidth"
    :class="!preview ? 'image_upload' : 'image_uploaded'"
    @dragover.prevent
    @drop.prevent="addDropFile"
  >
    <label
      for="assetsFieldHandle"
      class="d-flex align-center justify-center text-center cursor-pointer"
    >
      <div v-if="!imageUpload && !src">
        <div v-if="label" class="pa-3">
          {{ label }}
        </div>
        <div v-else>
          <v-icon large color="cancel"> mdi-image-plus </v-icon>
        </div>
      </div>
      <v-img
        v-else
        :src="showPreview"
        :height="height"
        :max-height="maxHeight"
        :width="width"
        :max-width="maxWidth"
        :contain="contain"
      ></v-img>
    </label>
    <v-file-input
      id="assetsFieldHandle"
      v-model="imageUpload"
      accept="image/*"
      style="display: none"
      multiple
    ></v-file-input>
  </v-sheet>
</template>

<script>
export default {
  name: "VImageUpload",
  props: {
    src: {
      type: String,
      default: "",
    },
    label: {
      type: [String],
      default: "",
    },
    height: {
      type: [Number, String],
      default: "100%",
    },
    width: {
      type: [Number, String],
      default: "100%",
    },
    maxHeight: {
      type: [Number, String],
      default: "100%",
    },
    maxWidth: {
      type: [Number, String],
      default: "100%",
    },
    contain: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    imageUpload: null,
    preview: null,
  }),
  computed: {
    showPreview() {
      if (this.src && this.preview) {
        return this.preview
      }
      return !this.src ? this.preview : this.urlImagePath(this.src)
    },
  },
  watch: {
    imageUpload() {
      if (this.imageUpload) {
        this.showLoadImage()
        this.$emit("upload", this.imageUpload)
      }
    },
  },
  methods: {
    addDropFile(e) {
      this.imageUpload = e.dataTransfer.files
    },
    showLoadImage() {
      const reader = new FileReader()
      reader.onload = (event) => {
        this.preview = event.target.result
      }
      reader.readAsDataURL(this.imageUpload[0])
    },
    reset() {
      this.imageUpload = null
      this.preview = null
    },
  },
}
</script>

<style lang="sass" scoped>
.image_upload
  border: 1px dashed #8aa2ae
  background-color: #f7fafc

.image_uploaded
  border: 1px solid #8aa2ae
  background-color: #f7fafc

label
  width: 100%
  height: 100%
</style>
