import axios from "axios"
import { _role } from "./extension"

export default {
  state: {
    list: [],
    _role,
  },
  mutations: {
    SET_LIST(state, payload) {
      state.list = payload
    },
  },
  actions: {
    usersPage(_, payload) {
      const params = new URLSearchParams(payload)
      return axios
        .get("/api/panel/users", { params })
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    getUsers({ commit }, payload) {
      const params = new URLSearchParams(payload)
      return axios
        .get("/api/getUsersByKeyword", params)
        .then((response) => {
          commit("SET_LIST", response.data.data)
          return response.data
        })
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    setUser(_, data) {
      return axios
        .post("/api/user/changeRole", data)
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    changeUserName(_, payload) {
      return axios
        .post("/api/changeUserName", payload)
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    changePwd(_, payload) {
      return axios
        .post("/api/changePwd", payload)
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    recoverPwd(_, payload) {
      return axios
        .post(`/api/recoverPwd`, payload)
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    changePass(_, data) {
      return axios
        .post("/api/user/changePass", data)
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    getUserById(_, { user_id }) {
      return axios
        .get(`/api/panel/user/getSmallInfo/${user_id}`)
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    removeUserById(_, data) {
      return axios
        .delete(`/api/user/${data.id}?comment=${data.comment}`)
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    restoreUserById(_, data) {
      return axios
        .get(`/api/panel/users/restore/${data.id}?comment=${data.comment}`)
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    activeUserById(_, data) {
      return axios
        .post(`/api/panel/user/active`, data)
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    addUser(_, payload) {
      return axios
        .post(`/api/panel/user/add`, payload)
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    userSignupActivate(_, { id }) {
      return axios
        .get(`/api/panel/user/signupActivateFromPanel/${id}`)
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    /**
     * Merchant Support
     */
    addManagerToMerchant(_, payload) {
      const params = new URLSearchParams(payload)
      return axios
        .post("/api/panel/manager/merchants/addMerchant", params)
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    removeManagerFromMerchant(_, payload) {
      const params = new URLSearchParams(payload)
      return axios
        .delete("/api/panel/manager/merchants/deleteMerchant", { params })
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    getManagerAttachedToMerchant(_, { user_id }) {
      return axios
        .get(`/api/panel/manager/merchants/getMerchants/${user_id}`)
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    getManagerByMerchantId(_, { merchant_id }) {
      return axios
        .get(`/api/panel/manager/merchants/getManagersOfMerchant/${merchant_id}`)
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },
  },
  getters: {
    userNameById: (state) => (id) => {
      if (!state.list.length) return ""
      let user = state.list.find((user) => user.id === id)

      return user ? `${user.name} (${user.email})` : ""
    },
    userList: (state) => {
      return state.list.map((one) => ({
        id: one.id,
        name: `${one.name} (${one.email})`,
      }))
    },

    roleList: (state) => {
      return Object.values(state._role)
    },

    isMerchantUserId: (state) => (id) => {
      return id == state._role.merchant.id
    },
    isManagerUserId: (state) => (id) => {
      return id == state._role.manager.id
    },
  },
}
