import axios from "axios"
export default {
  state: {
    list: [],
    status: {
      2: "Отправлено",
    },
  },
  mutations: {
    SET_LIST(state, payload) {
      state.list = payload
    },
  },
  actions: {
    list({ commit }, payload) {
      axios.post("/api/panel/commerceOffers/list", payload).then((response) => {
        commit("SET_LIST", response.data.data)
      })
    },
    add(_, payload) {
      return axios.post("/api/panel/commerceOffers/add", payload).then((response) => {
        return response.data
      })
    },
    edit(_, payload) {
      return axios.post("/api/panel/commerceOffers/edit", payload).then((response) => {
        return response.data
      })
    },
    get(_, payload) {
      const params = new URLSearchParams(payload)
      return axios.get("/api/panel/commerceOffers/get", { params }).then((response) => {
        return response.data
      })
    },
    delete(_, payload) {
      return axios.post("/api/panel/commerceOffers/delete", payload).then((response) => {
        return response.data
      })
    },
    notify(_, payload) {
      return axios.post("/api/panel/commerceOffers/notify", payload).then((response) => {
        return response.data
      })
    },
    findEmail(_, payload) {
      const params = new URLSearchParams(payload)
      return axios.get("/api/panel/commerceOffers/getEmails", { params }).then((response) => {
        return response.data.data
      })
    },
  },
  getters: {
    list: (state) => {
      return state.list
    },
    status: (state) => (value) => {
      return state.status[value] ? state.status[value] : "Ожидает"
    },
  },
}
