import axios from "axios"
export default {
  state: {
    list: [],
  },
  mutations: {
    SET_LIST(state, payload) {
      state.list = payload
    },
    RESET_LIST(state) {
      state.list = []
    },
  },
  actions: {
    getList({ commit }, payload) {
      const params = new URLSearchParams(payload)
      return axios
        .get("/api/panel/versions/listVersions", { params })
        .then((response) => {
          commit("SET_LIST", response.data.data)
          return response.data.data
        })
        .catch((error) => ({ error: JSON.stringify(error) }))
    },
    getChangesById(_, { id }) {
      return axios
        .get(`/api/panel/versions/${id}`)
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    addChangesList(_, payload) {
      return axios
        .post("/api/panel/versions/addVersion", payload)
        .then((response) => response.data.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },
    removeChanges(_, { id }) {
      return axios
        .delete(`/api/panel/versions/${id}`)
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },
    updateChanges(_, { id, payload }) {
      return axios
        .put(`/api/panel/versions/${id}`, { ...payload })
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },
  },
  getters: {
    getChangesList: (state) => {
      return state.list
    },
  },
}
