<template>
  <div class="wrap-button">
    <v-tooltip v-bind="aligments">
      <template #activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          :color="color"
          class="button-action-large"
          :class="classButton"
          :target="target"
          :to="to"
          :href="href"
          v-on="on"
          @click="$emit('click')"
        >
          <span v-if="textLeft"> {{ textLeft }}</span>
          <v-icon v-if="icon" :color="setIconColor"> {{ icon }} </v-icon>
          <span v-if="textRight"> {{ textRight }}</span>
        </v-btn>
      </template>
      <span>{{ hint }}</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: null,
    },
    hint: {
      type: String,
      default: null,
    },
    textRight: {
      type: String,
      default: null,
    },
    textLeft: {
      type: String,
      default: null,
    },
    color: {
      type: String,
      default: "white",
    },
    iconColor: {
      type: [Boolean, String],
      default: null,
    },
    alignment: {
      type: String,
      default: "bottom",
    },
    classButton: {
      type: String,
      default: null,
    },
    target: {
      type: String,
      default: null,
    },
    to: {
      type: String,
      default: null,
    },
    href: {
      type: String,
      default: null,
    },
    top: {
      type: [Boolean, Object],
      default: false,
    },
    bottom: {
      type: [Boolean, Object],
      default: false,
    },
  },
  computed: {
    aligments() {
      return {
        top: !!this.top,
        bottom: !!this.bottom,
      }
    },
    setIconColor() {
      return !this.iconColor ? "secondary" : this.iconColor
    },
  },
}
</script>

<style scoped>
.wrap-button {
  display: inline-block;
}
</style>
