import { deepFreeze } from "~/utils/helpers"

export const _role = deepFreeze({
  customer: {
    id: 1,
    text: "Покупатель",
  },
  admin: {
    id: 2,
    text: "Администратор",
  },
  merchant: {
    id: 4,
    text: "Мерчант",
  },
  manager: {
    id: 5,
    text: "Менеджер",
  },
})

//export const _definiteAdminsId = Object.freeze([2, 3, 25, 42, 58, 105, 395, 166, 237, 463, 535])
