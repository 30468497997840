import store from "~/store"
//import { _definiteAdminsId } from "~/store/modules/users/extension"

export function menuItem({ role_id, user_id }, step) {
  switch (role_id) {
    case 2:
    case 5:
      return menu({ role_id, user_id })
    case 4:
      return menuMerchant(step)
    default:
      store.commit("SET_ERROR_MESSAGE", "Текущая роль не определена")
      return []
  }
}

export const menu = ({ role_id, user_id }) => {
  const { appeals_count, orders_count, bugs_count } = store.state.notices

  let list = [
    {
      icon: "mdi-monitor-dashboard",
      title: "Аналитика",
      name: "Analytics",
      access: {
        role_id: [2, 5],
      },
    },
    {
      icon: "mdi-cart-outline",
      title: "Заказы",
      items: [
        {
          icon: "mdi-format-list-bulleted",
          title: "Список заказов",
          name: "Orders",
          notice: orders_count,
        },
        {
          icon: "mdi-clipboard-edit",
          title: "Список обращений",
          name: "OrdersAppeals",
        },
        {
          icon: "mdi-book-check",
          title: "Отчет по заказам",
          name: "OrdersReports",
        },
      ],
      access: {
        role_id: [2],
        //user_id: [..._definiteAdminsId],
      },
      notice: orders_count,
    },
    {
      icon: "mdi-basket",
      title: "Коммерческие предложения",
      name: "СommercialOffer",
      access: {
        role_id: [2, 5],
        //user_id: [..._definiteAdminsId],
      },
    },
    {
      icon: "mdi-notebook",
      title: "Товары",
      items: [
        {
          icon: "mdi-alpha-p-box",
          title: "Простые товары",
          name: "Products",
        },
        {
          icon: "mdi-alpha-m-box",
          title: "Мастер товары",
          name: "MasterProducts",
          notice: bugs_count,
        },
        {
          icon: "mdi-alpha-g-box",
          title: "Группы товаров",
          name: "GroupProducts",
        },
      ],
      access: {
        role_id: [2, 5],
      },
    },
    {
      icon: "mdi-notification-clear-all",
      title: "Товарные категории",
      name: "Categories",
      access: {
        role_id: [2],
      },
    },
    {
      icon: "mdi-card-bulleted-outline",
      title: "Характеристики",
      items: [
        {
          icon: "mdi-ungroup",
          title: "Группы",
          name: "CharacteristicGroups",
        },
        {
          icon: "mdi-format-list-bulleted-square",
          title: "Опции",
          name: "CharacteristicOptions",
        },
        {
          icon: "mdi-ballot",
          title: "Справочники",
          name: "ReferenceBooks",
        },
      ],
      access: {
        role_id: [2],
      },
    },
    {
      icon: "mdi-forum",
      title: "Отзывы",
      name: "Reviews",
      access: {
        role_id: [2],
        //user_id: [..._definiteAdminsId],
      },
    },
    {
      icon: "mdi-apps-box",
      title: "Бренды",
      name: "Brands",
      access: {
        role_id: [2],
        //user_id: [..._definiteAdminsId],
      },
    },
    {
      icon: "mdi-domain",
      title: "Мерчанты",
      name: "Merchants",
      access: {
        role_id: [2, 5],
        //user_id: [..._definiteAdminsId],
      },
    },
    {
      icon: "mdi-database-import",
      title: "Импорт",
      items: [
        {
          icon: "mdi-calendar-multiple-check",
          title: "Проверка прайса",
          name: "ImportCheck",
        },
        {
          icon: "mdi-new-box",
          title: "Новый импорт",
          name: "ImportData",
        },
        {
          icon: "mdi-playlist-check",
          title: "Журнал импорта",
          name: "ImportJournal",
        },
      ],
      access: {
        role_id: [2],
        //user_id: [..._definiteAdminsId],
      },
    },
    //{ icon: "mdi-shopping", title: "Покупатели", name: "Buyer" },
    {
      icon: "mdi-message-text-outline",
      title: "Список обращений",
      name: "Appeal",
      access: {
        role_id: [2],
        //user_id: [..._definiteAdminsId],
      },
      notice: appeals_count,
    },
    {
      icon: "mdi-account-multiple",
      title: "Пользователи",
      name: "Users",
      access: {
        role_id: [2],
        //user_id: [..._definiteAdminsId],
      },
    },
    {
      icon: "mdi-account-cash",
      title: "Система лояльности",
      name: "Loyalty",
      access: {
        role_id: [2],
        //user_id: [..._definiteAdminsId],
      },
    },
    {
      icon: "mdi-account",
      title: "Профиль пользователя",
      name: "Account",
      access: {
        role_id: [2, 5],
      },
    },
    {
      icon: "mdi-application-cog",
      title: "Настройка",
      items: [
        {
          icon: "mdi-truck-delivery",
          title: "Справочник доставки",
          name: "DeliveryBook",
        },
        {
          icon: "mdi-image-multiple-outline",
          title: "Банеры",
          name: "Banners",
        },
        {
          icon: "mdi-content-save-settings",
          title: "Парсер",
          name: "Parser",
        },
        {
          icon: "mdi-arrow-right-bold-circle-outline",
          title: "Слайдер",
          name: "HomeSliderBanners",
        },
        {
          icon: "mdi-arrow-right-bold-circle-outline",
          title: "Изображения",
          name: "OptimizeImage",
        },
      ],
      access: {
        role_id: [2],
        //user_id: [..._definiteAdminsId],
      },
    },
    {
      icon: "mdi-clipboard-text-search-outline",
      title: "Логи",
      items: [
        {
          icon: "mdi-account-details",
          title: "Пользователи",
          name: "LogsUsers",
        },
      ],
      access: {
        role_id: [2],
        //user_id: [..._definiteAdminsId],
      },
    },
    {
      icon: "mdi-text-box-multiple-outline",
      title: "Документация",
      items: [
        {
          icon: "mdi-history",
          title: "История обновлений",
          name: "HistoryChanges",
        },
        {
          icon: "mdi-palette",
          title: "Style Guide",
          name: "StyleGuide",
        },
      ],
      access: {
        role_id: [2, 5],
      },
    },
  ]

  return list.filter(({ access }) => {
    if (access.role_id && !access.role_id.includes(role_id)) return false

    if (access.user_id && !access.user_id.includes(user_id)) return false

    return true
  })
}

export const menuMerchant = (value) => {
  const step = value || 1
  const finalStep = store.state.merchants.merchants._finalMerchantStep

  const listStep = store.state.merchants.merchants._stepList
    .filter((one) => one.step)
    .reduce((acc, n) => ((acc[n.route] = n.step), acc), {})

  const menu = [
    {
      icon: "mdi-monitor-dashboard",
      title: "Аналитика",
      name: "Analytics",
      href: step < finalStep ? "#" : null,
      disabled: step < finalStep,
    },
    {
      icon: "mdi-store-outline",
      title: "Товары",
      name: "Products",
      href: step < finalStep ? "#" : null,
      disabled: step < finalStep,
    },
    {
      icon: "mdi-cart-arrow-down",
      title: "Заказы",
      name: "Orders",
      href: step < finalStep ? "#" : null,
      disabled: step < finalStep,
    },
    {
      icon: "mdi-newspaper-variant-outline",
      title: "Регистрационные данные",
      name: "MerchantRequisites",
      href: step <= listStep.requisites ? "#" : null,
      disabled: step <= listStep.requisites,
    },
    {
      icon: "mdi-book-open-outline",
      title: "О компании",
      name: "MerchantAbout",
      href: step <= listStep.about ? "#" : null,
      disabled: step <= listStep.about,
    },
    {
      icon: "mdi-text-box-check",
      title: "Оферта и документы",
      name: "MerchantOffer",
      href: step <= listStep.placement ? "#" : null,
      disabled: step <= listStep.placement,
    },
    {
      icon: "mdi-order-bool-ascending-variant",
      title: "Условия размещения",
      name: "MerchantPlacement",
      href: step <= listStep.offer ? "#" : null,
      disabled: step <= listStep.offer,
    },
    {
      icon: "mdi-file-download",
      title: "Загрузка товаров",
      name: "MerchantLoading",
      href: step <= listStep.loading ? "#" : null,
      disabled: step <= listStep.loading,
    },
    {
      icon: "mdi-store",
      title: "Склады",
      name: "MerchantStorage",
      href: step <= listStep.storage ? "#" : null,
      disabled: step <= listStep.storage,
    },
    {
      icon: "mdi-truck-delivery",
      title: "Службы доставки",
      name: "MerchantDelivery",
      href: step <= listStep.delivery ? "#" : null,
      disabled: step <= listStep.delivery,
    },
    {
      icon: "mdi-account-multiple",
      title: "Представители компании",
      name: "MerchantMember",
      href: step < finalStep ? "#" : null,
      disabled: step < finalStep,
    },
    {
      icon: "mdi-account-tie ",
      title: "Менеджеры",
      name: "MerchantManagers",
    },
    {
      icon: "mdi-account",
      title: "Профиль пользователя",
      name: "Account",
    },
  ]

  if (step < finalStep)
    menu.unshift({
      icon: "mdi-account-plus",
      title: "Регистрации продавца",
      name: "MerchantsRegistration",
    })

  return menu
}
