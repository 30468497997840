<template>
  <v-container fluid my-0 ma-3 pa-0 class="section-definition">
    <v-toolbar flat :extended="breadcrumbs.length > 0" class="transparent">
      <v-btn
        v-if="back"
        class="mr-2 button-back"
        color="cancel"
        dark
        outlined
        @click="$router.push(back)"
      >
        <v-icon> mdi-arrow-left </v-icon>
      </v-btn>
      <v-avatar class="elevation-1 rounded" color="white" size="40" :tile="tile">
        <v-icon v-if="icon" color="primary">{{ icon }}</v-icon>
        <span v-else class="primary--text">{{ title | first2Char }}</span>
      </v-avatar>
      <v-toolbar-title class="mx-4 font-weight-bold">{{ sectionTitle }}</v-toolbar-title>
      <v-toolbar-title v-if="breadcrumbs.length" slot="extension">
        <v-breadcrumbs :items="breadcrumbs" class="pl-0">
          <v-icon slot="divider">chevron_right</v-icon>
        </v-breadcrumbs>
      </v-toolbar-title>
      <slot name="left" />
      <v-spacer></v-spacer>
      <slot />
    </v-toolbar>
  </v-container>
</template>

<script>
import capitalize from "lodash/capitalize"
export default {
  name: "SectionDefinition",
  props: {
    title: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: "",
    },
    back: {
      type: Object,
      default: () => {},
    },
    breadcrumbs: {
      type: Array,
      default: () => [],
    },
    tile: {
      type: Boolean,
      default: true,
    },
    namespace: {
      type: String,
      default: null,
    },
  },
  computed: {
    heading() {
      return this.namespace ? `${capitalize(this.namespace)}.${this.title}.heading` : this.title
    },
    sectionTitle() {
      return this.namespace ? this.heading.replace("# ", "") : this.heading
    },
  },
}
</script>

<style lang="scss" scoped>
.toobar-extension {
  margin-left: 5px !important;
}

.breadcrumb-section {
  margin-left: -25px !important;
}

.action-btn {
  margin-left: 5px !important;
}

.button-back {
  height: 40px !important;
  min-width: 40px !important;
  padding: 0 !important;
  i {
    font-size: 24px !important;
  }
}
</style>
