import axios from "axios"

export default {
  state: {},
  getters: {},
  mutations: {},

  actions: {
    getBanners() {
      return axios.get("/api/banners/getBanners").then((response) => {
        return response.data
      })
    },

    addBanners(_, payload) {
      const config = { "content-type": "multipart/form-data" }
      const formData = new FormData()
      formData.append("is_active", 0)
      for (let key in payload) {
        formData.append(key, payload[key])
      }

      return axios.post("/api/banners/addBanner", formData, config).then((response) => {
        return response.data
      })
    },

    editBanner(_, payload) {
      const config = { "content-type": "multipart/form-data" }
      const formData = new FormData()
      for (let key in payload) {
        formData.append(key, payload[key])
      }
      return axios.post("/api/banners/editBanner", formData, config).then((response) => {
        return response.data
      })
    },

    deleteBanner(_, id) {
      return axios.post("/api/banners/deleteBanner", id).then((response) => {
        return response.data
      })
    },
  },
}
