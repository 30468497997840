import axios from "axios"
import { deepFreeze } from "~/utils/helpers"

const _userResponsibleType = deepFreeze({
  1: "Ответственный по наполнению",
  2: "Ответственный по проверке",
  3: "Ответственный по инструкции",
})

export default {
  state: {
    categories: [],
    allCategories: [],
    units: [],
    _userResponsibleType,
  },
  mutations: {
    SET_CATEGORIES(state, payload) {
      state.categories = payload
    },
    SET_ALL_CATEGORIES(state, payload) {
      state.allCategories = payload
    },
    SET_UNITS(state, payload) {
      state.units = payload
    },
  },
  actions: {
    getAllCategories({ commit }) {
      return axios
        .get("/api/getCategories")
        .then((response) => {
          commit("SET_ALL_CATEGORIES", response.data.categories)
          return response.data
        })
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    getPriceUnitList({ commit }) {
      return axios
        .get("/api/getPriceUnitList")
        .then((response) => {
          commit("SET_UNITS", response.data.priceUnitList)
          return response.data
        })
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    // setUnitbyCategoryIds(_, data) {
    //   return axios
    //     .post("/api/setUnitbyCategoryIds", data)
    //     .then((response) => {
    //       return response.data
    //     })
    //     .catch((error) => ({ error: JSON.stringify(error) }))
    // },

    // getCategoriesWithData() {
    //   return axios
    //     .get(`/api/getCategoriesWithData`)
    //     .then((response) => {
    //       return response.data.data
    //     })
    //     .catch((error) => ({ error: JSON.stringify(error) }))
    // },

    getCategoriesTree({ commit }, payload) {
      return (
        axios
          //.post("/api/getCategories", payload)
          .post("/api/panel/category/getCategoriesLonely", payload)
          .then((response) => {
            commit("SET_CATEGORIES", response.data.categories)
            return response.data
          })
          .catch((error) => ({ error: JSON.stringify(error) }))
      )
    },

    getCategoryById(_, payload) {
      return axios
        .get(`/api/getCategoryById/${payload}`)
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    addNewCategory({ commit }, payload) {
      return axios
        .post("/api/addNewCategory", payload)
        .then((response) => {
          commit("SET_CATEGORIES", response.data.categoriesTree)
          return response
        })
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    delCategories({ commit }, data) {
      if (data.category_id) {
        return axios
          .delete(`/api/panel/category/${data.id}?category_id=${data.category_id}`)
          .then((response) => {
            commit("SET_CATEGORIES", response.data.categoriesTree)
            return response.data
          })
          .catch((error) => ({ error: JSON.stringify(error) }))
      } else {
        return axios
          .delete(`/api/panel/category/${data.id}`)
          .then((response) => {
            commit("SET_CATEGORIES", response.data.categoriesTree)
            return response.data
          })
          .catch((error) => ({ error: JSON.stringify(error) }))
      }
    },

    changeCategory({ commit }, payload) {
      return axios
        .post("/api/panel/category/changeCategory", payload)
        .then((response) => {
          if (!response.data.error) {
            commit("SET_CATEGORIES", response.data.categoriesTree)
            return response.data
          }
        })
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    checkCategory(_, payload) {
      return axios
        .post("/api/panel/category/check", payload)
        .then((response) => {
          return response.data
        })
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    changeCategoryParent(_, payload) {
      return axios
        .post("/api/panel/category/parentCategory", payload)
        .then((response) => {
          if (response.error) {
            return response.data
          }
        })
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    /**
     * Ответственные пользователи категорий
     */
    addResponsibleUserToCategory(_, payload) {
      return axios
        .post("/api/panel/category/addResponsibleUserToCategory", payload)
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    deleteResponsibleUserFromCategory(_, payload) {
      return axios
        .post("/api/panel/category/deleteResponsibleUserFromCategory", payload)
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    getResponsibleUserToCategory(_, payload) {
      return axios
        .post("/api/panel/category/getResponsibleUserToCategory", payload)
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },
  },
  getters: {
    getCategoryNameById: (state) => (id) => {
      let cat = state.allCategories.length
        ? state.allCategories.find((cat) => cat.id === id)
        : false
      return cat ? cat.name : false
    },
    getCategoryById: (state) => (id) => {
      return state.allCategories.find((item) => item.id == id)
    },
    getCategoriesById: (state) => (id) => {
      if (!id) return []

      let arr = []
      let parent_id = id

      if (state.allCategories.length) {
        do {
          let cat = state.allCategories.find((cat) => cat.id === parent_id)
          arr.unshift(cat.name)
          parent_id = cat.parent_id
        } while (parent_id)
      }

      return arr
    },
    getAllUserResponsibleType(state) {
      return state._userResponsibleType
    },
  },
}
