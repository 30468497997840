import axios from "axios"
export default {
  state: {
    list: [],
  },
  mutations: {
    SET_LIST(state, payload) {
      state.list = payload
    },
  },
  actions: {
    list({ commit }, payload) {
      const params = new URLSearchParams(payload)
      axios.get("/api/panel/reviews/getAll", { params }).then((response) => {
        commit("SET_LIST", response.data.data)
      })
    },
  },
  getters: {
    list: (state) => {
      return state.list
    },
  },
}
