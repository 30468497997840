<template>
  <span>
    <v-button-action-large
      v-if="large"
      :icon="btnIcon"
      :icon-color="btnIconColor"
      :hint="tooltipTxt"
      :class-button="btnClasses"
      top
      @click="dialog = !dialog"
    />

    <v-tooltip v-else top>
      <template #activator="{ on, attrs }">
        <v-btn
          :color="btnColor"
          :dark="btnDark"
          :class="btnClasses"
          v-bind="attrs"
          v-on="on"
          @click="dialog = !dialog"
        >
          <v-icon v-if="btnIcon" :color="btnIconColor">
            {{ btnIcon }}
          </v-icon>
          {{ btnText }}
        </v-btn>
      </template>
      <span>{{ tooltipTxt }}</span>
    </v-tooltip>

    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          <span class="margin-auto">{{ title }}</span>
          <v-spacer />
          <v-btn icon @click="dialog = false">
            <v-icon> mdi-close </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text v-if="subtitle" class="text-center py-2">{{ subtitle }}</v-card-text>
        <v-card-text class="text-center py-2">
          <v-textarea
            v-model="comment"
            :error-messages="error ? 'Это поле обязательное!' : ''"
            label="Опишите причину выполняемого действия"
          />
        </v-card-text>
        <v-card-actions>
          <v-btn
            v-if="leftBtnTxt"
            :block="!rightBtnTxt"
            class="ma-0"
            color="primary"
            @click.stop="leftBtnClick"
          >
            {{ leftBtnTxt }}
          </v-btn>
          <template v-if="rightBtnTxt">
            <v-spacer></v-spacer>
            <v-btn text color="error" @click.stop="rightBtnClick">
              {{ rightBtnTxt }}
            </v-btn>
          </template>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>
<script>
export default {
  props: {
    large: { type: Boolean, default: false },
    title: { type: String, default: "" },
    subtitle: { type: String, default: "" },
    tooltipTxt: { type: String, default: "" },
    leftBtnTxt: { type: String, default: "" },
    rightBtnTxt: { type: String, default: "" },
    btnText: { type: String, default: "" },
    btnColor: { type: String, default: "" },
    btnClass: { type: String, default: "" },
    btnIcon: { type: String, default: "" },
    btnIconColor: { type: String, default: "light" },
    btnDark: { type: String, default: "" },
    btnClasses: { type: String, default: "" },
  },
  data: () => ({
    dialog: false,
    error: false,
    comment: "",
  }),
  watch: {
    dialog(val) {
      !val && this.close()
    },
  },
  methods: {
    leftBtnClick() {
      if (this.comment) {
        this.$emit("leftBtnEvent", this.comment)
        this.close()
      } else {
        this.error = true
        setTimeout(() => (this.error = false), 2000)
      }
    },
    rightBtnClick() {
      if (this.comment) {
        this.$emit("rightBtnEvent", this.comment)
        this.close()
      } else {
        this.error = true
        setTimeout(() => (this.error = false), 2000)
      }
    },
    close() {
      this.dialog = this.error = false
      this.comment = ""
    },
  },
}
</script>
