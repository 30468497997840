import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import vuetify from "./plugins/vuetify"
import errorMessages from "~/utils/errorMessages"
import axios from "axios"
import vuelidate from "vuelidate"
import { URL_BASE, URL_FRONTEND, URL_DASHBOARD } from "./utils/server_urls"
import "~/utils/extension"
import "~/plugins/vuse"
import "~/plugins/sweetAlert"
import "~/plugins/urls"
import "~/plugins/globalComponents"
import "~/plugins/mask"
import "./sass/main.scss"
import pkg from "!/package"
const VERSION = pkg.version
const isDev = process.env.NODE_ENV === "development"

// import * as Sentry from "@sentry/vue"
// import { BrowserTracing } from "@sentry/tracing"
// if (!isDev) {
//   Sentry.init({
//     Vue,
//     dsn: "https://a16e112ae73041a5a11c7633abf86267@o1175527.ingest.sentry.io/6272736",
//     logError: true,
//     integrations: [
//       new BrowserTracing({
//         routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//         tracingOrigins: ["localhost", "admin.bh.market", /^\//],
//       }),
//     ],
//     tracesSampleRate: 1.0,
//   })
// }

Vue.use(vuelidate)
// Vue.prototype.$baseURL = URL_BASE
Vue.prototype.$frontUrl = URL_FRONTEND
Vue.prototype.$adminUrl = URL_DASHBOARD
Vue.prototype.$projectVersion = VERSION

store.commit("SET_BASE_URL", URL_BASE)

axios.defaults.headers.common["Accept"] = "application/json"
axios.defaults.headers.post["Content-Type"] = "application/json; charset=utf-8"
axios.defaults.headers.get["Accept"] = "application/json"
axios.defaults.baseURL = URL_BASE

axios.interceptors.request.use(
  function (config) {
    store.commit("SET_PROCESSING", true)
    return config
  },
  function (error) {
    return Promise.reject(error)
  },
)

axios.interceptors.response.use(
  function (response) {
    if (response.data.error && isDev) {
      // eslint-disable-next-line no-use-before-define
      console.log({
        error: response.data.error,
        message: errorMessages(response.data.code || response.data.error),
        response: response.request.responseURL,
        code: response.data.code,
      })
    }

    if (response.data.error) {
      let errorCode = response.data.code || response.data.error

      // Critical ERRORS
      if (["ACCESS_ERROR", 1401].includes(errorCode)) {
        store.dispatch("clear_user")
      }

      store.commit("SET_ERROR_MESSAGE", errorMessages(errorCode))
    }
    store.commit("SET_PROCESSING", false)

    return response
  },
  function (error) {
    const responseErrors = error.request?.response ? JSON.parse(error.request?.response) : null
    const errors = responseErrors
      ? responseErrors.error
      : errorMessages(`SERVER_STATUS_${error.request.status}`)

    if (error.request) {
      store.commit("SET_ERROR_MESSAGE", errors)
      if ([0, 401, 402].includes(error.request.status)) store.dispatch("clear_user")
      return error
    }

    if (error.response) {
      store.commit("SET_ERROR_MESSAGE", errors)
      return error
    }

    store.commit("SET_ERROR_MESSAGE", "Извините, произошла непредвиденная ошибка")
    store.commit("SET_PROCESSING", false)
    store.dispatch("clear_user")
  },
)

function checkVersionUpdate() {
  const { isAdmin, isManager } = store.getters

  if (isDev || (!isAdmin && !isManager)) return

  const storage = localStorage.getItem("version")
  if (!storage) {
    localStorage.setItem("version", VERSION)
    return
  }

  if (storage != VERSION) {
    localStorage.setItem("version", VERSION)
    store.commit("SET_UPDATE_QUESTION", true)
  }
}

router.beforeEach((to, from, next) => {
  const { id, role_id } = store.state.user || false

  if (to.meta?.middleware && (!!id || !!role_id)) {
    const { userIds, roleIds } = to.meta.middleware
    let isMiddlewareUserId = false
    let isMiddlewareRoleId = false

    if (userIds) isMiddlewareUserId = to.meta.middleware.userIds.includes(id)
    if (roleIds) isMiddlewareRoleId = to.meta.middleware.roleIds.includes(role_id)

    if (userIds && !isMiddlewareUserId && !roleIds) {
      store.dispatch("clear_user")
      return
    }

    if (roleIds && userIds && !(isMiddlewareRoleId || isMiddlewareUserId)) {
      store.dispatch("clear_user")
      return
    }
  }

  // Авторизаци по ссылке
  if (to.query.profile) {
    store.commit("SET_TOKEN", to.query.profile)
    next({ name: "MerchantsRegistration" })
    return
  }

  // Действия при авторизации
  const { token } = store.state
  if (to.name == "LoginPage") {
    if (token) {
      store.dispatch("log_in_from_cache", token).then((res) => {
        if (res) {
          let merchant = null
          let isMerchantType = res.merchant && res.merchant.length

          merchant =
            isMerchantType &&
            res.merchant[0].step < store.state.merchants.merchants._finalMerchantStep

          if (merchant) {
            next({ name: "MerchantsRegistration" })
            return
          }

          // Временная проверка для мерчантов не заполнивших шаг "Условия размещения"
          merchant =
            isMerchantType &&
            res.merchant[0].step >= store.state.merchants.merchants._finalMerchantStep &&
            !res.merchant[0].merchant_terms_cooperation &&
            !res.merchant[0].merchant_terms_cooperation.cooperation_type

          if (merchant) {
            next({ name: "MerchantPlacement" })
            return
          }

          next({ name: "Analytics" })
        }

        return
      })
    }
    next()
    return
  }

  if (store.state.isAuthenticated) {
    if (
      to.name === "Analytics" &&
      store.state.merchant &&
      store.state.merchant.length &&
      store.state.merchant[0].step < store.state.merchants.merchants._finalMerchantStep
    ) {
      store.commit(
        "SET_INFO_MESSAGE",
        "Для продолжения работы в маркетплейсе необходимо заполнить регистрационные данные.",
      )
      next({ name: "MerchantsRegistration" })
      return
    }

    checkVersionUpdate()
    next()
  } else {
    // action refresh page
    if (token) {
      store.dispatch("log_in_from_cache", token).then((res) => {
        let isMerchantType = res.merchant && res.merchant.length

        if (res) {
          if (
            isMerchantType &&
            res.merchant[0].step < store.state.merchants.merchants._finalMerchantStep
          ) {
            next({ name: "MerchantsRegistration" })
            return
          }

          checkVersionUpdate()
          next()
        }
      })
    } else {
      next({ name: "LoginPage" })
    }
  }
})

export const app = new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app")
