const errors = {
  NOCATEGORIES: "Не выбраны категории",
  NOPROPS: "Категориям не назначены свойства",
  OPTIONEXISTS: "Свойство с таким именем уже существует",
  BRAND_IN_USE: "Данный бренд используется в товарах",
  IS_USED_IN_PRODUCTS: "Используется в товарах",
  SLUGNOTUNIQUE: "Такой slug уже есть!",
  MERCHANT_DONT_HAVE_THIS_PRODUCT: "У Вас нет доступа к этому товару!",
  DUBLICATE_IMAGE: "Это изображение уже загружено!",
  SYSTEM_ERROR: "Ошибка на сервере, обратитесь к администратору!",
  PRODUCT_DOESNT_EXIST: "Продукт с таким id не найден!",
  MASTER_PRODUCT_DOESNT_EXIST: "Мастер-продукт с таким id не найден!",
  IN_USE: "Характеристика привязана к категории. Удаление запрещено!",
  NOT_UNIQUE_SLUG: "Слаг должен быть уникальным!",
  PRODUCT_IN_ORDERS: "Удаление запрещено! Товар используется в заказах",
  PRODUCTS_IN_ORDERS: "Удаление запрещено! Товары находятся в заказах",
  OPTION_ERROR: "Выбранные товары для объединения имяют разные характеристики!",
  NO_OPTIONS_IN_MP: "В мастер-товаре отсутствуют характеристики!",
  PRODUCT_ALREADY_GROUPED: "Данный товар уже добавлен в группу.",
  MASTER_PRODUCT_ID_NOT_IN_GROUP: "Выбранный мастер продукт отсутствует в группе!",
  GP_HAS_NO_MASTER_PRODUCTS: "Группа товаров пуста!",
  ALREADY_GROUPED: "Выбранные мастер-товары уже сгруппированны!",
  TYPE_ERROR: "Выбранный формат данных не поддерживается",
  BAD_OLD_PASSWORD: "Введен неправильный текущий пароль",
  PASSWORDNOTCONFIRMED: "Новые пароли не совпадают",
  MASTER_PRODUCT_NOT_FOUND: "Масте-продукт не найден",
  NOT_UNIQUE_NAME: "Такое название уже существует",
  CATEGORY_ERROR: "Ошибка категории",
  PRICE_UNREACHABLE: "Прайс недоступен",
  IT_IS_NOT_GROUPED_PRODUCT: "Не является сгруппированным продуктом",
  IT_IS_NOT_MASTER_PRODUCT: "Не является мастер продуктом",
  BADPASSWORD: "Комбинация логина и пароля отсутствует",
  DONTEXIST: "Комбинация логина и пароля отсутствует",
  DUBLICATE_EMAIL: "Email адрес уже используется",
  EMAILNOTFOUND: "Пользователь с такой электронной почтой не найден.",
  NOTACTIVATED: "Пользователь не активирован.",
  NOTACTIVE: "Пользователь не активирован.",
  NOUSERID: "ID пользователя не указан",
  NOROLE: "Роль пользователя не указана",
  CANTDELETE: "Невозможно удалить",
  1401: "Вы не авторизованы или сессия устарела",
  SERVER_STATUS_401: "Вы не авторизованы или сессия устарела",
  SERVER_STATUS_403: "В доступе отказано",
  SERVER_STATUS_404: "Страница не найдена",
  SERVER_STATUS_500: "Внутренняя ошибка сервера",
  SERVER_STATUS_502: "Ошибочный шлюз",
  SERVER_STATUS_503: "Сервис недоступен",
  SERVER_STATUS_504: "Шлюз не отвечает",
}

export default function errorMessages(text) {
  return errors[text] || text || "Извините, произошла непредвиденная ошибка"
}
