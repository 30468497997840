import Vue from "vue"
import { URL_BASE, URL_DASHBOARD } from "~/utils/server_urls"

Vue.mixin({
  methods: {
    urlImagePath(uri) {
      if (!uri) return `${URL_DASHBOARD}/images/no-image.svg`
      return /^http/.test(uri) ? uri : `${URL_BASE}${uri}`
    },
    urlPath(uri) {
      if (!uri) return ""
      return /^http/.test(uri) ? uri : `${URL_DASHBOARD}${uri}`
    },
    pathJoin(parts, sep) {
      var separator = sep || "/"
      var replace = new RegExp(separator + "{1,}", "g")
      return parts.join(separator).replace(replace, separator)
    },
  },
})

/* export const fromImageToUrl = (uri) => {
  if (!uri) return '/images/no-image.svg'

  return /^http/.test(uri) ? uri : `${ URL_BASE }${ uri }`
}
 */
