import LazyLoad from "~/utils/AsyncRouteHelper"
import VuseRoutes from "./routes/vuse"
const AppSidebar = () => import("~/layouts/App/Sidebar")

const allRoutes = [...VuseRoutes]

const routes = allRoutes.map((route) => {
  const { params, defaultComponentPath, noNavs } = route

  const componentObj = !noNavs
    ? {
        components: {
          default: LazyLoad(defaultComponentPath),
          sidebar: AppSidebar,
        },
      }
    : {
        component: LazyLoad(defaultComponentPath),
      }

  return {
    ...params,
    ...componentObj,
  }
})

export default [{ path: "/", redirect: "/login" }, ...routes]
