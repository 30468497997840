import axios from "axios"
export default {
  state: {
    topRequest: {},
    topMasterProduct: {},
    topRequestQuery: null,
    topMasterProductQuery: null,
    merchantMetrics: {},
    merchantMetricsQuery: null,
    masterProductVisits: {
      totalUniqueVisits: 0,
      totalVisits: 0,
    },
    countMerchant: 0,
    countBuyers: 0,
    countBrands: 0,
    adminMetrics: {
      nps: {
        nps: 0,
        good: 0,
        neutral: 0,
        bad: 0,
      },
      countOrders: [],
      countProducts: {
        is_active: 0,
        is_description: 0,
        is_images: 0,
        is_froze: 0,
        unprocessed: 0,
      },
      countMasterProducts: {
        master_product_count: 0,
        with_description_count: 0,
        with_images_count: 0,
      },
    },
    adminMetricsQuery: null,
  },
  mutations: {
    SET_TOP_REQUEST(state, payload) {
      state.topRequest = payload
    },
    SET_TOP_MASRTER_PRODUCT(state, payload) {
      state.topMasterProduct = payload
    },
    SET_TOP_REQUEST_QUERY(state, payload) {
      state.topRequestQuery = payload
    },
    SET_TOP_MASRTER_PRODUCT_QUERY(state, payload) {
      state.topMasterProductQuery = payload
    },
    SET_COUNT_MERCHANT(state, payload) {
      state.countMerchant = payload
    },
    SET_COUNT_BUYERS(state, payload) {
      state.countBuyers = payload
    },
    SET_COUNT_BRANDS(state, payload) {
      state.countBrands = payload
    },

    SET_COUNT_PRODUCTS(state, payload) {
      state.adminMetrics.countProducts = payload
    },
    SET_COUNT_MASTER_PRODUCTS(state, payload) {
      state.adminMetrics.countMasterProducts = payload
    },
    SET_MASTER_PRODUCTS_VISITS(state, payload) {
      state.masterProductVisits = payload
    },

    SET_NPS(state, payload) {
      state.adminMetrics.nps = payload
    },
    SET_COUNT_ORDERS(state, payload) {
      state.adminMetrics.countOrders = payload
    },
    SET_MERCHANT_METRICS(state, payload) {
      state.merchantMetrics = payload
    },
    SET_MERCHANT_METRICS_QUERY(state, payload) {
      state.merchantMetricsQuery = payload
    },
    SET_ADMIN_METRICS_QUERY(state, payload) {
      state.adminMetricsQuery = payload
    },

    RESET_MERCHANT_METRICS(state) {
      state.merchantMetrics = {}
      state.merchantMetricsQuery = null
      state.masterProductVisits = {
        totalUniqueVisits: 0,
        totalVisits: 0,
      }
    },
  },
  actions: {
    getTopRequest({ commit }, params) {
      return axios
        .get("/api/panel/analytics/getTopRequest", { params })
        .then((response) => {
          commit("SET_TOP_REQUEST", response.data.data)
          return response.data
        })
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    getTopMasterProduct({ commit }, params) {
      return axios
        .get("/api/panel/analytics/getTopMasterProduct", { params })
        .then((response) => {
          commit("SET_TOP_MASRTER_PRODUCT", response.data.data)
          return response.data
        })
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    getCountProducts({ commit }, params) {
      return axios
        .get("/api/panel/analytics/getCountProducts", { params })
        .then((response) => {
          commit("SET_COUNT_PRODUCTS", response.data.data)
          return response.data
        })
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    getCountMasterProducts({ commit }, params) {
      return axios
        .get("/api/panel/analytics/getCountMasterProducts", { params })
        .then((response) => {
          commit("SET_COUNT_MASTER_PRODUCTS", response.data.data)
          return response.data
        })
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    getCountMerchants({ commit }, params) {
      return axios
        .get("/api/panel/analytics/getCountMerchants", { params })
        .then((response) => {
          commit("SET_COUNT_MERCHANT", response.data.data.count_merchants)
          return response.data
        })
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    getCountBuyers({ commit }, params) {
      return axios
        .get("/api/panel/analytics/getCountBuyers", { params })
        .then((response) => {
          commit("SET_COUNT_BUYERS", response.data.data.count_buyers)
          return response.data
        })
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    getCountBrands({ commit }, params) {
      return axios
        .get("/api/panel/analytics/getCountBrands", { params })
        .then((response) => {
          commit("SET_COUNT_BRANDS", response.data.data.count_brands)
          return response.data
        })
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    getNps({ commit }, params) {
      return axios
        .get("/api/panel/analytics/getNps", { params })
        .then((response) => {
          commit("SET_NPS", response.data.data)
          return response.data
        })
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    getCountOrders({ commit }, params) {
      return axios
        .get("/api/panel/analytics/getCountOrders", { params })
        .then((response) => {
          commit("SET_COUNT_ORDERS", response.data.data)
          return response.data
        })
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    getMerchantMetrics({ commit }, params) {
      return axios
        .get("/api/panel/analytics/getMerchantMetrics", { params })
        .then((response) => {
          commit("SET_MERCHANT_METRICS", response.data.data)
          return response.data
        })
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    getMasterProductVisits({ commit }, payload) {
      const params = new URLSearchParams(payload)
      return axios
        .get("/api/panel/merchant/getMasterProductVisits", { params })
        .then((response) => {
          const data = {
            totalUniqueVisits: response.data?.totalUniqueVisits || 0,
            totalVisits: response.data?.totalVisits || 0,
          }
          commit("SET_MASTER_PRODUCTS_VISITS", data)
          return data
        })
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    getTop() {
      return new Promise((resolve) => {
        axios.get("/api/metric/admin/top").then((response) => {
          resolve(response.data.top5)
        })
      })
    },

    getMaster() {
      return new Promise((resolve) => {
        axios.get("/api/metric/admin/master").then((response) => {
          resolve(response.data.metric)
        })
      })
    },

    getMerchantBrands() {
      return new Promise((resolve) => {
        axios.get("/api/metric/admin/merchant/brands").then((response) => {
          resolve(response.data.metric)
        })
      })
    },

    merchantPeriod(_, id) {
      return new Promise((resolve) => {
        axios.get(`/api/metric/merchant/period?merchant_id=${id}`).then((response) => {
          resolve(response.data.metric)
        })
      })
    },
  },
  getters: {
    topRequest: (state) => {
      return {
        text: state.topRequest,
        query: state.topRequestQuery,
      }
    },
    topMasterProduct: (state) => {
      return {
        products: state.topMasterProduct,
        query: state.topMasterProductQuery,
      }
    },

    countMerchant: (state) => {
      return state.countMerchant
    },
    countBuyers: (state) => {
      return state.countBuyers
    },
    countBrands: (state) => {
      return state.countBrands
    },
    countProducts: (state) => {
      return state.adminMetrics.countProducts
    },
    countMasterProducts: (state) => {
      return state.adminMetrics.countMasterProducts
    },
    nps: (state) => {
      return state.adminMetrics.nps
    },
    countOrders: (state) => {
      return state.adminMetrics.countOrders
    },
    masterProductVisits: (state) => {
      return state.masterProductVisits
    },
    merchantMetrics: (state) => {
      return {
        info: state.merchantMetrics,
        query: state.merchantMetricsQuery,
      }
    },
    adminMetrics: (state) => {
      return {
        info: state.adminMetrics,
        query: state.adminMetricsQuery,
      }
    },
  },
}
