import axios from "axios"
export default {
  state: {},
  mutations: {},
  getters: {},
  actions: {
    getWithDoubleImages(_, payload) {
      return axios
        .post("/api/panel/masters/getWithDoubleImages", payload)
        .then((response) => {
          return response.data.master_products
        })
        .catch((error) => ({ error: JSON.stringify(error) }))
    },
    getProductsByKeyword(_, payload) {
      return axios
        .post("/api/panel/products/getProductsByKeyword", payload)
        .then((response) => {
          return response.data.products
        })
        .catch((error) => ({ error: JSON.stringify(error) }))
    },
  },
}
