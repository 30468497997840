import axios from "axios"
export default {
  state: {},
  mutations: {},
  actions: {
    getUsersStatusesNotification(_, payload) {
      const params = new URLSearchParams(payload)
      return axios
        .get(
          "/api/panel/system_jobs/order_status_notifications/getUsersOfMerchantWithNotificationStatusesSettings",
          { params },
        )
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    addUsersStatusesNotification(_, payload) {
      return axios
        .post("/api/panel/system_jobs/order_status_notifications/addUser", payload)
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    deleteUsersStatusesNotification(_, payload) {
      return axios
        .post("/api/panel/system_jobs/order_status_notifications/deleteUser", payload)
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    addTelegramId(_, payload) {
      return axios
        .post("/api/panel/notification/addTelegramId", payload)
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    checkTelegramMessageSending(_, payload) {
      return axios
        .get("/api/panel/notification/checkTelegramMessageSending", payload)
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    pushOrderStatusNotification(_, { id }) {
      return axios
        .post(`/api/order/createInvoiceNotification/${id}`)
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    userListNotification(_, payload) {
      return axios
        .post(`/api/panel/notification/list`, payload)
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },
  },

  getters: {},
}
