import axios from "axios"
export default {
  state: {
    list: [],
    type: null,
    types: [
      {
        id: 1,
        value: "Строка",
      },
      {
        id: 2,
        value: "Число",
      },
      {
        id: 3,
        value: "Справочник",
      },
    ],
    displayType: {
      view_detail: {
        default: [
          {
            id: 1,
            value: "Селект",
          },
          {
            id: 2,
            value: "Автокомплит",
          },
          {
            id: 3,
            value: "Радиокнопка - горизонтальная",
          },
          {
            id: 4,
            value: "Радиокнопка - вертикальная",
          },
        ],
        3: [
          {
            id: 5,
            value: "Селект - название + картинка",
          },
          {
            id: 6,
            value: "Автокомплит - название + картинка",
          },
          {
            id: 7,
            value: "Радиокнопка - горизонтальная название + картинка",
          },
          {
            id: 8,
            value: "Радиокнопка - вертикальная название + картинка",
          },
        ],
      },
      view_filter: {
        default: [
          {
            id: 1,
            value: "Селект",
          },
          {
            id: 2,
            value: "Автокомплит",
          },
          {
            id: 3,
            value: "Чекбокс - горизонтальная",
          },
          {
            id: 4,
            value: "Чекбокс - вертикальная",
          },
        ],
        2: [
          {
            id: 5,
            value: "Число от-до",
          },
          {
            id: 6,
            value: "Число от-до с ползунком",
          },
        ],
        3: [
          {
            id: 7,
            value: "Селект - название + картинка",
          },
          {
            id: 8,
            value: "Аавтокомплит - название + картинка",
          },
          {
            id: 9,
            value: "Чекбокс - горизонтальная название + картинка",
          },
          {
            id: 10,
            value: "Чекбокс - вертикальная название + картинка",
          },
        ],
      },
    },
  },
  mutations: {
    SET_OPTIONS(state, payload) {
      state.list = payload
    },
    SET_TYPE(state, payload) {
      state.type = payload
    },
  },
  actions: {
    setTypeOption({ commit }, id) {
      commit("SET_TYPE", id)
    },
    getOptions({ commit }) {
      axios.get("/api/options/list").then((res) => {
        commit("SET_OPTIONS", res.data.options)
      })
    },
    getOptionsGroup(_, data) {
      console.log(data)
      return axios.get(`/api/panel/optionsGroup/list`).then((res) => {
        return res.data.data
      })
    },
    getOptionById(_, id) {
      return axios.get(`/api/options/getOption/${id}`).then((res) => {
        return res.data.options
      })
    },
    getOptionGroupById(_, id) {
      return axios.get(`/api/panel/optionsGroup/${id}`).then((res) => {
        return res.data
      })
    },
    addOption({ commit }, payload) {
      return axios.post("/api/options/add", payload).then((res) => {
        commit("SET_OPTIONS", res.data.options)
        return res.data
      })
    },
    addOptionGroup(_, payload) {
      return axios.post("/api/panel/optionsGroup/add", payload).then((res) => {
        return res.data
      })
    },
    updateOption({ commit }, payload) {
      axios.put(`/api/options/${payload.id}`, payload.data).then((res) => {
        commit("SET_OPTIONS", res.data.options)
      })
    },
    updateOptionGroup(_, payload) {
      return axios.post(`/api/panel/optionsGroup/edit`, payload).then((res) => {
        return res.data
      })
    },
    delOption({ commit }, id) {
      axios.delete(`/api/options/${id}`).then((res) => {
        commit("SET_OPTIONS", res.data.options)
      })
    },
    delOptionGroup(_, id) {
      return axios.delete(`/api/panel/optionsGroup/delete/${id}`).then((res) => {
        return res.data
      })
    },
    addOptionToCategory({ commit }, payload) {
      return new Promise((resolve) => {
        axios.post("/api/categoryoptions/AddOption", payload).then((res) => {
          commit("project/SET_ALL_CATEGORIES", res.data.categories, {
            root: true,
          })
          commit("SET_OPTIONS", res.data.BhOptions)
          resolve(res.data)
        })
      })
    },
    unlinkOption({ commit }, payload) {
      return new Promise((resolve) => {
        axios.post("/api/categoryoptions/unlinkOption", payload).then((res) => {
          commit("project/SET_ALL_CATEGORIES", res.data.categories, {
            root: true,
          })
          commit("SET_OPTIONS", res.data.BhOptions)
          resolve(res.data)
        })
      })
    },
  },
  getters: {
    viewDetailOptions: (state) => {
      return [
        ...state.displayType.view_detail.default,
        ...(state.displayType.view_detail[state.type] || []),
      ]
    },
    viewFilterOptions: (state) => {
      return [
        ...state.displayType.view_filter.default,
        ...(state.displayType.view_filter[state.type] || []),
      ]
    },
  },
}
