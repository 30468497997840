import Vue from "vue"
import VueSweetalert2 from "vue-sweetalert2"
import "sweetalert2/dist/sweetalert2.min.css" // use sweetalert2 sass
import errorMessages from "~/utils/errorMessages"

Vue.use(VueSweetalert2)

Vue.mixin({
  methods: {
    errorMessagesAlert: function (title, text) {
      return this.$swal.fire({
        toast: true,
        timer: 3000,
        timerProgressBar: true,
        position: "top-end",
        title: errorMessages(title),
        html: text || false,
        icon: "error",
        showConfirmButton: false,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer)
          toast.addEventListener("mouseleave", this.$swal.resumeTimer)
        },
      })
    },

    successAlert(title, text) {
      return this.$swal.fire({
        toast: true,
        timer: 3000,
        timerProgressBar: true,
        position: "top-end",
        title: title || "Выполнено успешно!",
        html: text || false,
        icon: "success",
        showConfirmButton: false,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer)
          toast.addEventListener("mouseleave", this.$swal.resumeTimer)
        },
      })
    },
    errorAlert: function (title, text) {
      return this.$swal.fire({
        toast: true,
        timer: 3000,
        timerProgressBar: true,
        position: "top-end",
        title: title || "Произошла ошибка!",
        html: text || false,
        icon: "error",
        showConfirmButton: false,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer)
          toast.addEventListener("mouseleave", this.$swal.resumeTimer)
        },
      })
    },
    warningAlert: function (title, text) {
      return this.$swal.fire({
        toast: true,
        timer: 3000,
        timerProgressBar: true,
        position: "top-end",
        title: title || "Предостережение!",
        html: text || false,
        icon: "warning",
        showConfirmButton: false,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer)
          toast.addEventListener("mouseleave", this.$swal.resumeTimer)
        },
      })
    },
    infoAlert: function (title, text) {
      return this.$swal.fire({
        toast: true,
        timer: 3000,
        timerProgressBar: true,
        position: "top-end",
        title: title || "Информация!",
        html: text || false,
        icon: "info",
        showConfirmButton: false,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer)
          toast.addEventListener("mouseleave", this.$swal.resumeTimer)
        },
      })
    },

    questionMessagesConfirm: function (title, text, isCanceled) {
      return this.$swal.fire({
        position: "center",
        title: title,
        html: text || false,
        icon: "question",
        showConfirmButton: true,
        confirmButtonColor: this.$vuetify.theme.currentTheme.primary,
        confirmButtonText: "Подтвердить",
        showCancelButton: isCanceled,
        cancelButtonColor: this.$vuetify.theme.currentTheme.cancel,
        cancelButtonText: "Отмена",
      })
    },

    infoMessagesConfirm: function (title, text, isCanceled) {
      return this.$swal.fire({
        position: "center",
        title: title,
        html: text || false,
        icon: "info",
        showConfirmButton: true,
        confirmButtonColor: this.$vuetify.theme.currentTheme.primary,
        confirmButtonText: "ОК",
        showCancelButton: isCanceled,
        cancelButtonColor: this.$vuetify.theme.currentTheme.cancel,
        cancelButtonText: "Отмена",
      })
    },

    warningMessagesConfirm: function (title, text, isCanceled) {
      return this.$swal.fire({
        position: "center",
        title: title,
        html: text || false,
        icon: "warning",
        confirmButtonColor: this.$vuetify.theme.currentTheme.error,
        confirmButtonText: "Подтвердить",
        showCancelButton: isCanceled,
        cancelButtonColor: this.$vuetify.theme.currentTheme.cancel,
        cancelButtonText: "Отмена",
      })
    },

    errorMessagesConfirm: function (title, text, isCanceled) {
      return this.$swal.fire({
        position: "center",
        title: title,
        html: text || false,
        icon: "warning",
        iconColor: "#EF5350",
        confirmButtonColor: this.$vuetify.theme.currentTheme.error,
        confirmButtonText: "Подтвердить",
        showCancelButton: isCanceled,
        cancelButtonColor: this.$vuetify.theme.currentTheme.cancel,
        cancelButtonText: "Отмена",
      })
    },
  },
})
