<template>
  <v-dialog v-model="dialog" width="500">
    <template #activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on">
        <v-text-field
          :value="inputValue"
          readonly
          chips
          :label="label"
          :filled="filled"
          :background-color="backgroundColor"
        />
      </div>
    </template>

    <v-card>
      <v-card-title>
        {{ label }}
      </v-card-title>

      <v-card-text>
        <h4 class="pb-2">День недели</h4>
        <v-chip-group
          :value="value.days"
          column
          multiple
          mandatory
          class="pb-4"
          @change="(value) => update('days', value)"
        >
          <v-chip v-for="day in weekDayList" :key="day" active-class="primary--text">
            {{ day }}
          </v-chip>
        </v-chip-group>

        <h4 class="pb-2">Время</h4>
        <v-text-field
          v-mask="vMask.timeRangeMask"
          :value="value.time"
          filled
          autocomplete="new-password"
          placeholder="00:00-23:59"
          @input="(value) => update('time', value)"
        />
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="save"> I accept </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { getWeekDay } from "~/utils/helpers"
export default {
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    backgroundColor: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: "Время работы",
    },
    filled: {
      type: [String, Boolean],
      default: false,
    },
  },
  data: () => ({
    dialog: false,
    weekDayList: getWeekDay("short"),
  }),
  computed: {
    inputValue() {
      let str = ""
      if (this.value.days) str += getWeekDay("short", this.value.days).join(", ")

      if (this.value.time) str += ` c ${this.value.time}`

      return str
    },
  },
  methods: {
    save() {
      this.dialog = false
    },
    update(key, value) {
      if (JSON.stringify(this.value[key]) == JSON.stringify(value)) return

      this.$emit("input", { ...this.value, [key]: value })
    },
  },
}
</script>
