import Vue from "vue"
import { VueMaskDirective, VueMaskFilter } from "v-mask"
// https://text-mask.github.io/text-mask/
import createNumberMask from "text-mask-addons/dist/createNumberMask"

Vue.directive("mask", VueMaskDirective)
Vue.filter("VMask", VueMaskFilter)

const floatMask = createNumberMask({
  prefix: "",
  allowDecimal: true,
  includeThousandsSeparator: false,
  allowNegative: false,
})

const phone = (value) => {
  if (!value || value == "") return []

  if (value.substr(0, 1) == 8) {
    if (["8", "8 "].includes(value)) return ["8 "]

    let numbers = value
    numbers = numbers.replace(/^8/, "")
    numbers = numbers.replace(/[^0-9]/g, "")

    if (!numbers.length) return []
    if (numbers.length <= 3) return ["8 ", "(", numbers.substring(0, 3)]
    if (numbers.length <= 6)
      return ["8 ", "(", numbers.substring(0, 3), ")", numbers.substring(3, 6)]

    return [
      "8 ",
      "(",
      numbers.substring(0, 3),
      ")",
      numbers.substring(3, 6),
      "-",
      numbers.substring(6, 10),
    ]
  }

  if (["7", "+7"].includes(value)) return ["+7"]

  let numbers = value
  numbers = numbers.replace(/^\+7|^7/, "")
  numbers = numbers.replace(/[^0-9]/g, "")

  if (!numbers.length) return []
  if (numbers.length <= 3) return ["+7", "(", numbers.substring(0, 3)]
  if (numbers.length <= 6) return ["+7", "(", numbers.substring(0, 3), ")", numbers.substring(3, 6)]

  return [
    "+7",
    "(",
    numbers.substring(0, 3),
    ")",
    numbers.substring(3, 6),
    "-",
    numbers.substring(6, 10),
  ]
}

const timeMask = (value) => {
  const hours = [/[0-2]/, value.charAt(0) === "2" ? /[0-3]/ : /[0-9]/]
  const minutes = [/[0-5]/, /[0-9]/]
  return value.length > 2 ? [...hours, ":", ...minutes] : hours
}

const timeRangeMask = (value) => {
  const numbers = value.replace(/[^0-9]/g, "")
  if (numbers.length > 4) {
    return [...timeMask(numbers.substring(0, 4)), "-", ...timeMask(numbers.substring(4))]
  }
  return [...timeMask(numbers)]
}

const integerMask = (value) => {
  const numbers = value.replace(/[^0-9]/g, "")
  return !numbers || numbers == "" ? [0] : [numbers]
}

Vue.mixin({
  data: () => ({
    vMask: {
      // price,
      bic: "#########",
      bank: "####################",
      phone,
      timeMask,
      timeRangeMask,
      integerMask,
      floatMask,
    },
  }),
})

Vue.filter("formatPhone", (value) => {
  return phone(value).join("")
})
