import axios from "axios"
export default {
  actions: {
    get(_, data) {
      const params = new URLSearchParams(data)
      return axios.get("/api/v2/panel/request_price_and_rest/list", { params }).then((res) => {
        return res.data.items
      })
    },

    change(_, data) {
      return axios.put(`/api/v2/panel/request_price_and_rest/${data.id}`, data).then((res) => {
        return res.data
      })
    },

    remove(_, id) {
      return axios.delete(`/api/v2/panel/request_price_and_rest/${id}`).then((res) => {
        return res.data
      })
    },
  },
}
