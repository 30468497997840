<template>
  <v-dialog v-if="value" :value="value" :width="width" @click:outside="onClose">
    <v-card>
      <v-card-text class="pa-4">
        <v-btn fab dark x-small class="btn-close" @click="onClose">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-img
          :src="getImageSrc"
          :height="height"
          :max-height="maxHeight"
          :min-height="minHeight"
          :width="width"
          :max-width="maxWidth"
          :min-width="minWidth"
          :contain="contain"
          :lazy-src="lazySrc"
          :aspect-ratio="aspectRatio"
          :alt="alt"
          :position="position"
          @error="errorHandler"
        >
          <template #placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular indeterminate color="primary" size="25"> </v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "VImages",
  props: {
    value: {
      type: [String, Boolean],
      default: null,
    },
    alt: {
      type: String,
      default: "Image",
    },
    height: {
      type: [Number, String],
      default: "100%",
    },
    width: {
      type: [Number, String],
      default: "100%",
    },
    maxHeight: {
      type: [Number, String],
      default: "100%",
    },
    maxWidth: {
      type: [Number, String],
      default: "100%",
    },
    minHeight: {
      type: [Number, String],
      default: 30,
    },
    minWidth: {
      type: [Number, String],
      default: 30,
    },
    contain: {
      type: Boolean,
      default: true,
    },
    lazySrc: {
      type: String,
      default: "/images/no-image.svg",
    },
    aspectRatio: {
      type: [Number, String],
      default: 1,
    },
    position: {
      type: String,
      default: "center center",
    },
  },
  data: () => ({
    hasError: false,
  }),
  computed: {
    getImageSrc() {
      let src = this.value || null
      if (src === "/images/no-image.svg" || !src) return "/images/no-image.svg"

      return this.hasError || src.split(".").length == 1
        ? "/images/no-image.svg"
        : this.urlImagePath(src)
    },
  },
  methods: {
    errorHandler() {
      this.hasError = true
      this.$emit("error", true)
    },
    onClose() {
      this.$emit("input", false)
    },
  },
}
</script>

<style scoped>
.btn-close {
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 1;
}
</style>
