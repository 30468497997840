import axios from "axios"
export default {
  state: {},
  mutations: {},
  getters: {},
  actions: {
    getMasterProducts(_, payload) {
      return axios
        .post("/api/panel/master_products/getMasterProducts", payload)
        .then((response) => {
          return response.data
        })
    },

    addOffer(_, data) {
      return axios
        .post("api/panel/master_products/addOffer", data)
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    unlinkOffer(_, data) {
      return axios
        .post("api/masterproducts/unlinkOffer", data)
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    changeMasterProductsCategories(_, payload) {
      return axios
        .post("/api/changeMasterProductsCategories", payload)
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    setMainImageMaster(_, id) {
      return axios
        .post(`/api/panel/masters/setMainImage/${id}`)
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    uploadMasterImage(_, data) {
      const config = { "content-type": "multipart/form-data" }
      const formData = new FormData()
      formData.append("photo", data.photo)
      if (data.id) {
        formData.append("id", data.id)
      }
      return axios
        .post("/api/panel/masters/image", formData, config)
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    updateMasterProductImage(_, data) {
      const config = { "content-type": "multipart/form-data" }
      const formData = new FormData()
      formData.append("photo", data.photo)
      if (data.id) {
        formData.append("id", data.id)
      }

      return axios
        .post("/api/panel/masters/replaceImage", formData, config)
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    changeSelectMaster(_, payload) {
      return axios
        .post("/api/changeSelectMaster", payload)
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    changeSelectMasterFiles(_, data) {
      const config = { "content-type": "multipart/form-data" }
      return axios
        .post("/api/changeSelectMasterFiles", data, config)
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    changeSelectMasterImages(_, data) {
      const config = { "content-type": "multipart/form-data" }
      return axios
        .post("/api/changeSelectMasterImages", data, config)
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    changeSelectMasterVideos(_, data) {
      return axios
        .post("/api/changeSelectMasterVideo", data)
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    changeSelectMasterFillingStatus(_, data) {
      return axios
        .post("/api/panel/master_products/setCheckDataMasterProducts", data)
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    uploadMasterFiles(_, data) {
      const config = { "content-type": "multipart/form-data" }
      return axios
        .post("/api/master/files", data, config)
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    delUploadFile(_, data) {
      return axios
        .post("/api/master/file/delete", data)
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    renameUploadFile(_, data) {
      return axios
        .post("/api/master/file/rename", data)
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    updateMasterProduct(_, payload) {
      return axios
        .post("/api/panel/master_products/updateMasterProduct", payload)
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    disableMaster(_, data) {
      return axios
        .get(`/api/masterproducts/disable/${data.id}?comment=${data.comment}`)
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    enableMaster(_, data) {
      return axios
        .get(`/api/masterproducts/enable/${data.id}?comment=${data.comment}`)
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    // deleteMaster(_, id) {
    //   return new Promise((resolve) => {
    //     axios.delete(`/api/masterProduct/${id}`).then((response) => {
    //       resolve(response.data)
    //     })
    //   })
    // },

    addOptionValues(_, data) {
      let type = data.master_product_id ? "master_product" : "product"
      return axios
        .post(`/api/${type}/optionvalues/addOptionValues`, data)
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    getOptionValues(_, data) {
      return axios
        .post(`/api/panel/options/getOptionsOfMps`, data)
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    addImagesFromProducts(_, data) {
      return axios
        .post(`/api/addImagesFromProducts`, data)
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    getProductImagesByMasterProductId(_, payload) {
      const params = new URLSearchParams(payload)
      return axios
        .get(`/api/panel/products/getProductImagesByMasterProductId`, { params })
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    copyProductImagesToMasterProduct(_, payload) {
      return axios
        .post(`/api/panel/products/copyProductImagesToMasterProduct`, payload)
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    async getCountViewsMasterProduct(_, payload) {
      const params = new URLSearchParams(payload)
      return await axios
        .get("/api/front/masterProducts/getCountViewsMasterProduct", { params })
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },
  },
}
