import axios from "axios"

export default {
  state: {},
  getters: {},
  mutations: {},

  actions: {
    getUserLogsByUserId(_, payload) {
      const params = new URLSearchParams(payload)
      return axios.get("/api/panel/getUserLogsByUserId", { params }).then((response) => {
        return response.data
      })
    },
  },
}
