import Vue from "vue"
import Vuex from "vuex"
import actions from "./actions"
import mutations from "./mutations"
import getters from "./getters"
import users from "./modules/users"
import categories from "./modules/categories"
import brands from "./modules/project/brands"
import products from "./modules/project/products"
import masterProduct from "./modules/masterProduct/product"
import masterParser from "./modules/masterProduct/parser"
import masterBugs from "./modules/masterProduct/bugs"
import groupProduct from "./modules/groupProduct/product"
import importModule from "./modules/import"
import appeal from "./modules/appeal"
import request_price_and_rest from "./modules/request_price_and_rest"
import metric from "./modules/metric"
import delivery from "./modules/delivery"
import logs from "./modules/logs"
import merchants from "./modules/merchants"
import notifications from "./modules/notifications"
import options from "./modules/project/options"
import order from "./modules/cart/order"
import referenceBook from "./modules/project/referenceBook"
import banners from "./modules/settings/banners"
import deliveryBook from "./modules/settings/deliveryBook"
import parser from "./modules/settings/parser"
import offers from "./modules/offers"
import reviews from "./modules/reviews"
import externalAPI from "./modules/externalAPI"
import historyChanges from "./modules/documentation/historyChanges"
import test from "./modules/test"

Vue.use(Vuex)
const store = new Vuex.Store({
  state: {
    imagehost: null, // Константа, отвечающая за домен для картинок
    error_message: null,
    info_message: null,
    update_question: false,
    notices: {
      appeals_count: 0,
      orders_count: 0,
      bugs_count: 0,
    },
    statuses: [],
    user: {},
    merchant: null,
    processing: false,
    isAuthenticated: null,
    token: localStorage.getItem("token"),
    miniVariant: false,
    country: [],
    currency: [],
    menu: [],
  },
  getters,
  mutations,
  actions,
  modules: {
    users: {
      namespaced: true,
      modules: {
        users,
      },
    },
    import: {
      namespaced: true,
      modules: {
        importModule,
      },
    },
    appeal: {
      namespaced: true,
      modules: {
        appeal,
      },
    },
    request_price_and_rest: {
      namespaced: true,
      modules: {
        request_price_and_rest,
      },
    },
    metric: {
      namespaced: true,
      modules: {
        metric,
      },
    },
    delivery: {
      namespaced: true,
      modules: {
        delivery,
      },
    },
    project: {
      namespaced: true,
      modules: {
        products,
        brands,
        options,
        referenceBook,
      },
    },
    categories: {
      namespaced: true,
      modules: {
        categories,
      },
    },
    merchants: {
      namespaced: true,
      modules: {
        merchants,
      },
    },
    notifications: {
      namespaced: true,
      modules: {
        notifications,
      },
    },
    mProduct: {
      namespaced: true,
      modules: {
        masterProduct,
        masterParser,
        masterBugs,
      },
    },
    gProduct: {
      namespaced: true,
      modules: {
        groupProduct,
      },
    },
    settings: {
      namespaced: true,
      modules: {
        banners,
        deliveryBook,
        parser,
      },
    },
    logs: {
      namespaced: true,
      modules: {
        logs,
      },
    },
    cart: {
      namespaced: true,
      modules: {
        order,
      },
    },
    offers: {
      namespaced: true,
      modules: {
        offers,
      },
    },
    reviews: {
      namespaced: true,
      modules: {
        reviews,
      },
    },
    external: {
      namespaced: true,
      modules: {
        externalAPI,
      },
    },
    test: {
      namespaced: true,
      modules: {
        test,
      },
    },
    documentation: {
      namespaced: true,
      modules: {
        historyChanges,
      },
    },
  },
})

export default store
