import Vue from "vue"

export const _logs = (...params) => process.env.NODE_ENV !== "production" && console.log(...params)

Vue.mixin({
  methods: {
    _logs,
  },
})

export const COLORS_NAME = {
  green: "#25b281",
  yellow: "#ffcd56",
  red: "#ff6384",
  purple: "#8549ba",
  blue: "#4dc9f6",
  indigo: "#537bc4",
  orange: "#f4a026",
  pink: "#FF9AA2",
  violet: "#957DAD",
  anakiwa: "#96BAFF",
  watusi: "#FFDFD3",
  mint: "#4bc0c0",
  matisse: "#166a8f",
  norway: "#A8C898",
  brown: "#38292b",
  grey: "#58595b",
  black: "#000000",
}

export const COLORS_MAP = Object.values(COLORS_NAME)

export function addAlpha(color, opacity) {
  let _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255)
  return color + _opacity.toString(16).toUpperCase()
}

export function color(index, alpha = 0.7) {
  return addAlpha(COLORS_MAP[index % COLORS_MAP.length], alpha)
}

export function colors(alpha = 0.7) {
  return COLORS_MAP.map((one) => addAlpha(one, alpha))
}

export function namedColor(index) {
  return COLORS_NAME[index]
}

export function namedColorAlpha(index, opacity) {
  return addAlpha(COLORS_NAME[index], opacity)
}

export const uuid = () => Math.random().toString(36).slice(2)

export function camel(str) {
  const camel = (str || "").replace(/-([^-])/g, (g) => g[1].toUpperCase())

  return capitalize(camel)
}

export function camelActual(str) {
  return (str || "").replace(/-(\w)/g, (_, c) => (c ? c.toUpperCase() : ""))
}

export function kebab(str) {
  return (str || "").replace(/([a-z])([A-Z])/g, "$1-$2").toLowerCase()
}

export function capitalize(str) {
  str = str || ""

  return `${str.substr(0, 1).toUpperCase()}${str.slice(1)}`
}

export function declension(num, words) {
  let cases = [2, 0, 1, 1, 1, 2]
  return words[num % 100 > 4 && num % 100 < 20 ? 2 : cases[num % 10 < 5 ? num % 10 : 5]]
}

export function declensionDay(num) {
  return declension(num, ["день", "дня", "дней"])
}

export function isOnSale(variants) {
  return variants.some((variant) => {
    return parseFloat(variant.price) < parseFloat(variant.compareAtPrice)
  })
}

export function randomChar(str) {
  return str.charAt(Math.floor(Math.random() * str.length))
}

export function randomNumber(min, max) {
  return Math.floor(Math.random() * max) + min
}

export function randomString(length = 5) {
  let text = ""
  const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"

  for (let i = 0; i < length; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length))
  }

  return text
}

export function getMathRandom(length) {
  return Math.floor(Math.pow(10, length - 1) + Math.random() * 9 * Math.pow(10, length - 1))
}

export function timeZero(val) {
  return val < 10 ? "0" + val : val
}

export function setDateTime(str, type) {
  const date = str ? new Date(str) : new Date()

  if (!type || type == "end") {
    date.setUTCHours(23, 59, 59, 999)
  } else {
    date.setUTCHours(0, 0, 0, 0)
  }

  const dateStr = date.toISOString()
  const vDate = dateStr.slice(0, 10)
  const vTime = dateStr.slice(11, 19)
  return `${vDate} ${vTime}`
}

export function b64toBlob(data, sliceSize) {
  let block = data.split(";")
  let contentType = block[0].split(":")[1] || ""
  let realData = block[1].split(",")[1]

  sliceSize = sliceSize || 512

  let byteCharacters = atob(realData)
  let byteArrays = []

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    let slice = byteCharacters.slice(offset, offset + sliceSize)

    let byteNumbers = new Array(slice.length)
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    let byteArray = new Uint8Array(byteNumbers)

    byteArrays.push(byteArray)
  }

  let blob = new Blob(byteArrays, { type: contentType })

  return { blob, contentType }
}

export function sortDate(arr, desc) {
  return desc
    ? arr.slice().sort((a, b) => new Date(b) - new Date(a))
    : arr.slice().sort((a, b) => new Date(a) - new Date(b))
}

export function getWeekDay(type = "long", isObject, params) {
  if (!["long", "short"].includes(type.toLowerCase())) return false

  const list = {
    short: ["Пн", "Вт", "Ср", "Чт", "Пт", "Сб", "Вс"],
    long: ["Понедельник", "Вторник", "Среда", "Четверг", "Пятница", "Суббота", "Воскресенье"],
  }

  if (params && Array.isArray(params)) {
    params.sort()

    if (isObject) return params.map((one) => ({ value: one, text: list[type][one] }))

    return params.map((one) => list[type][one])
  }

  return isObject ? list[type].map((one, index) => ({ value: index, text: one })) : list[type]
}

export function generatePassword() {
  const lowerLetters = "abcdefghijklmnopqrstuvwxyz"
  const upperLetters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ"
  const numbers = "0123456789"
  const symbols = "!?@#$%^&*|/<>()=%,.+~_-"
  const passAt = [lowerLetters, upperLetters, numbers, symbols].join("")

  const singl = []
  singl.push(randomChar(lowerLetters))
  singl.push(randomChar(upperLetters))
  singl.push(randomChar(numbers))
  singl.push(randomChar(symbols))

  const passArray = Array.from({ length: 8 }).map(() => randomChar(passAt))

  return [...singl, ...passArray].sort(() => Math.random() - 0.5).join("")
}

export function calculateDays(value, date) {
  if (!value) return []
  if (!date) date = new Date()

  return Math.sign(value)
    ? new Date(date.setDate(date.getDate() - Math.abs(value)))
    : new Date(date.setDate(date.getDate() + Math.abs(value)))
}

export function fillObject(sample, obj) {
  for (const [key, value] of Object.entries(sample)) {
    if (value != null && typeof value == "object" && obj[key]) {
      sample[key] = fillObject(value, obj[key])
    } else {
      if (obj[key] != null) sample[key] = obj[key]
    }
  }

  return sample
}

export function durationTime(milliseconds) {
  if (isNaN(milliseconds) || milliseconds < 0) {
    return null
  }

  let d, h, m, s, ms
  s = Math.floor(milliseconds / 1000)
  m = Math.floor(s / 60)
  s = s % 60
  h = Math.floor(m / 60)
  m = m % 60
  d = Math.floor(h / 24)
  h = h % 24
  ms = Math.floor((milliseconds % 1000) * 1000) / 1000
  return { d, h, m, s, ms }
}

export function percentDiff(a, b, ads) {
  return ads ? 100 * Math.abs((b - a) / a) : (100 * (b - a)) / a
  //   : 100 * ((a - b) / ((a + b) / 2))
}

export function deepFreeze(object) {
  var propNames = Object.getOwnPropertyNames(object)

  for (let name of propNames) {
    let value = object[name]

    object[name] = value && typeof value === "object" ? deepFreeze(value) : value
  }

  return Object.freeze(object)
}

export function calculateCooperationType({
  cooperation_type,
  created_at,
  count_orders,
  defaultPeriodValue,
}) {
  if (
    (!cooperation_type && ![1, 2].includes(cooperation_type)) ||
    (!created_at && !count_orders) ||
    !defaultPeriodValue
  )
    return false

  if (cooperation_type == 2) {
    const dateStart = new Date()
    const dateEnd = new Date(created_at)
    dateEnd.setDate(dateEnd.getDate() + defaultPeriodValue)
    return dateEnd.getTime() < dateStart.getTime() ? "finish" : `До ${dateEnd.toLocaleString()}`
  }

  if (cooperation_type == 1) {
    const orders = defaultPeriodValue - count_orders
    return orders <= 0
      ? "finish"
      : `Осталось ${orders} ${declension(orders, ["заказ", "заказа", "заказов"])}`
  }
}
