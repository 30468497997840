<template>
  <v-tooltip :top="top">
    <template #activator="{ on, attrs }">
      <span :class="classess" v-bind="attrs" v-on="on"> <slot /> </span>
    </template>
    <template #default>
      <div class="text-small">{{ description }}</div>
    </template>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    top: {
      type: Boolean,
      default: true,
    },
    small: {
      type: Boolean,
      default: false,
    },
    description: {
      type: String,
      default: "",
    },
  },
  computed: {
    classess() {
      return {
        "text-small": this.small,
      }
    },
  },
}
</script>

<style scoped>
.v-tooltip__content {
  padding: 4px 8px;
}
</style>
