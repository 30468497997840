<template>
  <v-row class="d-flex justify-center align-center">
    <v-col class="py-3 px-1" cols="auto">
      <v-btn :disabled="isDisabledFirst" class="button" color="primary" @click="nextPage('first')">
        <v-icon> mdi-page-first </v-icon>
      </v-btn>
    </v-col>
    <v-col class="py-3 px-1" cols="auto">
      <v-btn
        :disabled="isDisabledFirst"
        class="button"
        color="primary"
        @click="nextPage('backward')"
      >
        <v-icon> mdi-chevron-left </v-icon>
      </v-btn>
    </v-col>
    <v-col class="py-3 px-1" cols="auto">
      <v-text-field
        class="pa-0 ma-0 width-200 dense-input-inset"
        :value="currentPage"
        :suffix="'из ' + totalPage"
        :max="totalPage"
        :min="1"
        hide-details
        outlined
        filled
        @input="formerPage"
      />
    </v-col>
    <v-col class="py-3 px-1" cols="auto">
      <v-btn :disabled="isDisabledLast" class="button" color="primary" @click="nextPage('forward')">
        <v-icon> mdi-chevron-right </v-icon>
      </v-btn>
    </v-col>
    <v-col class="py-3 px-1" cols="auto">
      <v-btn :disabled="isDisabledLast" class="button" color="primary" @click="nextPage('last')">
        <v-icon> mdi-page-last </v-icon>
      </v-btn>
    </v-col>
    <v-col v-if="pageSize" class="py-3 px-1" cols="auto">
      <v-select
        :value="pageSizeValue"
        :items="pageSizeItems"
        persistent-hint
        single-line
        outlined
        filled
        hide-details
        item-text="text"
        item-value="value"
        return-object
        class="pa-0 ml-3 width-200 dense-select-inset"
        @change="changePageSize"
      ></v-select>
    </v-col>
  </v-row>
</template>

<script>
import debounce from "lodash/debounce"
export default {
  props: {
    currentPage: {
      type: Number,
      default: 1,
    },
    totalPage: {
      type: Number,
      default: 1,
    },
    pageSizes: {
      type: Array,
      default: () => [10, 30, 50],
    },
    pageSize: {
      type: [String, Number],
      default: null,
    },
  },
  computed: {
    pageSizeValue() {
      return this.pageSize || this.pageSizes[0]
    },
    pageSizeItems() {
      return this.pageSizes.map((page) => ({ value: page, text: `${page} / стр.` }))
    },
    isDisabledFirst() {
      return this.currentPage <= 1
    },
    isDisabledLast() {
      return this.currentPage >= this.totalPage
    },
  },
  methods: {
    nextPage(value) {
      let page = this.currentPage
      if (value === "backward" && this.currentPage > 1) page = this.currentPage - 1

      if (value === "forward" && page < this.totalPage) page = this.currentPage + 1

      if (value == "last") page = this.totalPage

      if (value == "first") page = 1

      this.$emit("nextPage", page)
      this.$emit("input", page)
    },
    formerPage: debounce(function (value) {
      let page = Number.parseInt(value) || this.currentPage

      if (page >= this.totalPage) page = this.totalPage

      if (page <= 1) page = 1

      this.$emit("nextPage", page)
      this.$emit("input", page)
    }, 1000),
    changePageSize: debounce(function ({ value }) {
      const size = Number.parseInt(value) || this.pageSizes[0]
      this.$emit("changePageSize", size)
    }, 250),
  },
}
</script>

<style scoped lang="scss">
.button {
  height: 32px !important;
  min-width: 32px !important;
  padding: 0 !important;
}
</style>
