const metaOrder = {
  meta: {
    middleware: {
      //userIds: [..._definiteAdminsId],
      roleIds: [4],
    },
  },
}

export default [
  {
    params: {
      path: "/login",
      name: "LoginPage",
    },
    defaultComponentPath: "Pages/Authentication/Login",
    noNavs: true,
  },
  {
    params: {
      path: "/analytics",
      name: "Analytics",
    },
    defaultComponentPath: "Pages/analytics/index",
  },
  {
    params: {
      path: "/brands",
      name: "Brands",
    },
    defaultComponentPath: "Pages/brands/index",
  },
  {
    params: {
      path: "/brands/:brand_id",
      name: "brand",
    },
    defaultComponentPath: "Pages/brands/id",
  },
  {
    params: {
      path: "/merchants",
      name: "Merchants",
    },
    defaultComponentPath: "Pages/merchants/index",
  },
  {
    params: {
      path: "/merchants/:merchant_id/",
      name: "Merchant",
    },
    defaultComponentPath: "Pages/merchants/id",
  },
  {
    params: {
      path: "/merchants-registration",
      name: "MerchantsRegistration",
    },
    defaultComponentPath: "Pages/merchants-registration/index",
  },
  {
    params: {
      path: "/cabinet",
      children: [
        {
          path: "merchant-requisites",
          name: "MerchantRequisites",
        },
        {
          path: "merchant-about",
          name: "MerchantAbout",
        },
        {
          path: "merchant-offer",
          name: "MerchantOffer",
        },
        {
          path: "merchant-placement",
          name: "MerchantPlacement",
        },
        {
          path: "merchant-loading",
          name: "MerchantLoading",
        },
        {
          path: "merchant-storage",
          name: "MerchantStorage",
        },
        {
          path: "merchant-delivery",
          name: "MerchantDelivery",
        },
        {
          path: "merchant-managers",
          name: "MerchantManagers",
        },
        {
          path: "merchant-notification",
          name: "MerchantNotification",
        },
        {
          path: "merchant-member",
          name: "MerchantMember",
        },
      ],
    },
    defaultComponentPath: "Pages/cabinet/index",
  },
  {
    params: {
      path: "/categories",
      name: "Categories",
    },
    defaultComponentPath: "Pages/categories/index",
  },
  {
    params: {
      path: "/categories/:category_id",
      name: "Сategory",
    },
    defaultComponentPath: "Pages/categories/id",
  },
  {
    params: {
      path: "/characteristic-groups",
      name: "CharacteristicGroups",
    },
    defaultComponentPath: "Pages/options/groups",
  },
  {
    params: {
      path: "/characteristic-groups/edit",
      name: "CharacteristicGroup",
    },
    defaultComponentPath: "Pages/options/group",
  },
  {
    params: {
      path: "/characteristic-options",
      name: "CharacteristicOptions",
    },
    defaultComponentPath: "Pages/options/index",
  },
  {
    params: {
      path: "/characteristic-options/edit",
      name: "CharacteristicOptionsEdit",
    },
    defaultComponentPath: "Pages/options/edit",
  },
  {
    params: {
      path: "/reference-books",
      name: "ReferenceBooks",
    },
    defaultComponentPath: "Pages/reference-books/index",
  },
  {
    params: {
      path: "/reference-books/:book_id",
      name: "ReferenceBook",
    },
    defaultComponentPath: "Pages/reference-books/id",
  },
  {
    params: {
      path: "/reference-books/:book_id/edit",
      name: "ReferenceBookEdit",
    },
    defaultComponentPath: "Pages/reference-books/edit",
  },
  {
    params: {
      path: "/reviews",
      name: "Reviews",
    },
    defaultComponentPath: "Pages/reviews/index",
  },
  {
    params: {
      path: "/products",
      name: "Products",
    },
    defaultComponentPath: "Pages/products/index",
  },
  {
    params: {
      path: "/products/test",
      name: "ProductsTest",
    },
    defaultComponentPath: "Pages/products/test",
  },
  {
    params: {
      path: "/products/new",
      name: "product_new",
    },
    defaultComponentPath: "Pages/products/new",
  },
  {
    params: {
      path: "/products/:product_id",
      name: "Product",
    },
    defaultComponentPath: "Pages/products/id",
  },
  {
    params: {
      path: "/master-products",
      name: "MasterProducts",
    },
    defaultComponentPath: "Pages/master-products/index",
  },
  {
    params: {
      path: "/master-products/:product_id",
      name: "MasterProduct",
    },
    defaultComponentPath: "Pages/master-products/id",
  },
  {
    params: {
      path: "/group-products",
      name: "GroupProducts",
    },
    defaultComponentPath: "Pages/group-products/index",
  },
  {
    params: {
      path: "/group-products/:product_id",
      name: "GroupProduct",
    },
    defaultComponentPath: "Pages/group-products/id",
  },
  {
    params: {
      path: "/orders",
      name: "Orders",
      ...metaOrder,
    },
    defaultComponentPath: "Pages/orders/index",
  },
  {
    params: {
      path: "/order/reports",
      name: "OrdersReports",
      ...metaOrder,
    },
    defaultComponentPath: "Pages/orders/report",
  },
  {
    params: {
      path: "/order/appeals",
      name: "OrdersAppeals",
      ...metaOrder,
    },
    defaultComponentPath: "Pages/orders/appeal",
  },
  {
    params: {
      path: "/orders/:order_id",
      name: "Order",
      ...metaOrder,
    },
    defaultComponentPath: "Pages/orders/id",
  },
  {
    params: {
      path: "/commercial-offer",
      name: "СommercialOffer",
    },
    defaultComponentPath: "Pages/commercial-offer/index",
  },
  {
    params: {
      path: "/commercial-offer/add",
      name: "AddСommercialOffer",
    },
    defaultComponentPath: "Pages/commercial-offer/offer",
  },
  {
    params: {
      path: "/commercial-offer/edit/:offer_id",
      name: "EditСommercialOffer",
    },
    defaultComponentPath: "Pages/commercial-offer/offer",
  },
  {
    params: {
      path: "/importdata",
      name: "ImportData",
    },
    defaultComponentPath: "Pages/import/ImportData",
  },
  {
    params: {
      path: "/importcheck",
      name: "ImportCheck",
    },
    defaultComponentPath: "Pages/import/ImportCheck",
  },
  {
    params: {
      path: "/importjournal",
      name: "ImportJournal",
    },
    defaultComponentPath: "Pages/import/ImportJournal",
  },
  {
    params: {
      path: "/appeal",
      name: "Appeal",
    },
    defaultComponentPath: "Pages/appeal/index",
  },
  {
    params: {
      path: "/users",
      name: "Users",
    },
    defaultComponentPath: "Pages/users/index",
  },
  {
    params: {
      path: "/users/:user_id",
      name: "User",
    },
    defaultComponentPath: "Pages/users/id",
  },
  {
    params: {
      path: "/account",
      name: "Account",
    },
    defaultComponentPath: "Pages/account/index",
  },
  {
    params: {
      path: "/settings/banners",
      name: "Banners",
    },
    defaultComponentPath: "Pages/settings/banners/index",
  },
  {
    params: {
      path: "/settings/delivery-book",
      name: "DeliveryBook",
    },
    defaultComponentPath: "Pages/settings/delivery-book/index",
  },
  {
    params: {
      path: "/settings/parser",
      name: "Parser",
    },
    defaultComponentPath: "Pages/settings/parser/index",
  },
  {
    params: {
      path: "/settings/parser/add",
      name: "AddParser",
    },
    defaultComponentPath: "Pages/settings/parser/id",
  },
  {
    params: {
      path: "/settings/parser/edit/:parser_id",
      name: "EditParser",
    },
    defaultComponentPath: "Pages/settings/parser/id",
  },
  {
    params: {
      path: "/settings/homeSlider",
      name: "HomeSliderBanners",
    },
    defaultComponentPath: "Pages/settings/homeSlider",
  },
  {
    params: {
      path: "/settings/image",
      name: "OptimizeImage",
    },
    defaultComponentPath: "Pages/settings/image",
  },
  {
    params: {
      path: "/logs/users",
      name: "LogsUsers",
    },
    defaultComponentPath: "Pages/logs/users",
  },
  {
    params: {
      path: "/documentation/styleguide",
      name: "StyleGuide",
    },
    defaultComponentPath: "Pages/documentation/styleguide/index",
  },
  {
    params: {
      path: "/documentation/history-changes",
      name: "HistoryChanges",
    },
    defaultComponentPath: "Pages/documentation/history-changes/index",
  },
  {
    params: {
      path: "/documentation/history-changes/add",
      name: "AddHistoryChanges",
    },
    defaultComponentPath: "Pages/documentation/history-changes/id",
  },
  {
    params: {
      path: "/documentation/history-changes/:action/:history_id",
      name: "EditHistoryChanges",
    },
    defaultComponentPath: "Pages/documentation/history-changes/id",
  },
  {
    params: {
      path: "/testing",
      name: "Testing",
    },
    defaultComponentPath: "Pages/testing/index",
  },
  {
    params: {
      path: "/editor",
      name: "Editor",
    },
    defaultComponentPath: "Pages/testing/editor/index",
  },
  {
    params: {
      path: "*",
      name: "PageNotFound",
    },
    noNavs: true,
    defaultComponentPath: "Pages/Errors/Error404",
  },

  /** временные методы **/
  {
    params: {
      path: "/lising",
      name: "Lising",
    },
    defaultComponentPath: "Pages/lising/index",
  },
  {
    params: {
      path: "/loyalty",
      name: "Loyalty",
    },
    defaultComponentPath: "Pages/loyalty/index",
  },
  {
    params: {
      path: "/testing/getWithDoubleImages",
      name: "DoubleImages",
    },
    defaultComponentPath: "Pages/testing/doubleImages",
  },
  {
    params: {
      path: "/testing/descFind",
      name: "DescFind",
    },
    defaultComponentPath: "Pages/testing/descFind",
  },
]
