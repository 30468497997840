import axios from "axios"
export default {
  state: {
    nds: [],
  },
  mutations: {
    SET_NDS(state, payload) {
      state.nds = payload
    },
  },
  actions: {
    getProducts(_, payload) {
      return axios
        .post("/api/getProducts", payload)
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    getProductsOffer(_, payload) {
      return axios
        .post("api/panel/products/getProducts", payload)
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    getProductById(_, payload) {
      return axios
        .get(`/api/panel/products/getProductById/${payload}`)
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    updatePriceDate(_, payload) {
      return axios
        .post(`/api/panel/product/updatePrice`, payload)
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    getMasterProductById(_, payload) {
      return axios
        .get(`/api/panel/master_products/getProductById/${payload}`)
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    getUserLogs(_, payload) {
      return new Promise((resolve) => {
        axios
          .get(`/api/panel/getUserLogs/?model=${payload.model}&model_id=${payload.model_id}`)
          .then((response) => {
            resolve(response.data.data.logs.reverse())
          })
      })
    },

    changeProductsCategories(_, payload) {
      return axios
        .post("/api/panel/products/changeProductsCategories", payload)
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    actionProductsByIds(_, payload) {
      return axios
        .post("/api/actionProductsByIds", payload)
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    addProduct(_, payload) {
      return axios
        .post("/api/addProduct", payload)
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    setMainImage(_, id) {
      return axios
        .post(`/api/panel/product/setMainImage/${id}`)
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    updateProduct(_, payload) {
      return axios
        .post("/api/panel/products/updateProduct", payload)
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    addNdsByIds(_, payload) {
      return axios
        .post("/api/addNds", payload)
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    disableTovar(_, data) {
      return axios
        .post(`/api/product/disable/${data.id}?comment=${data.comment}`)
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    enableTovar(_, data) {
      return axios
        .post(`/api/product/enable/${data.id}?comment=${data.comment}`)
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    deleteTovar(_, data) {
      return axios
        .delete(`/api/panel/products/${data.id}?comment=${data.comment}`)
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    undeleteTovar(_, data) {
      return axios
        .get(`/api/panel/product/undelete/${data.id}?comment=${data.comment}`)
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    addToBlackList(_, data) {
      return axios
        .post(`/api/panel/product/blacklist/`, data)
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    deleteFromBlackList(_, data) {
      return axios
        .delete(`/api/panel/product/blacklist/${data.id}?comment=${data.comment}`)
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    uploadProductImage(_, data) {
      const config = { "content-type": "multipart/form-data" }
      const formData = new FormData()
      formData.append("photo", data.photo)
      if (data.id) {
        formData.append("id", data.id)
      }
      return axios
        .post("/api/product/image", formData, config)
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    updateProductImage(_, data) {
      const config = { "content-type": "multipart/form-data" }
      const formData = new FormData()
      formData.append("photo", data.photo)
      if (data.id) {
        formData.append("id", data.id)
      }

      return axios
        .post("/api/panel/products/replaceImage", formData, config)
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    addGross(_, data) {
      return axios
        .post("/api/product/gross/add", data)
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    editGross(_, data) {
      return axios
        .put(`/api/product/gross/${data.id}`, data)
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    deleteGross(_, id) {
      return axios
        .delete(`/api/product/gross/${id}`)
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    selectProductsToMaster(_, payload) {
      return axios
        .post(`/api/panel/products/selectProductsToMaster`, payload)
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    async getCountViewsProduct(_, payload) {
      const params = new URLSearchParams(payload)
      return await axios
        .get("/api/front/products/getCountViewsProduct", { params })
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    getNds({ commit }) {
      axios.get("api/nds").then((response) => {
        commit("SET_NDS", response.data.nds)
      })
    },

    findMultisearch(_, payload) {
      let url = new URL(process.env.VUE_APP_URL_MULTISEARCH)
      url.searchParams.set("query", payload)
      return fetch(url)
        .then((response) => {
          return response.json()
        })
        .then((data) => {
          return data.results
        })
    },
    findSearchBooster(_, payload) {
      let url = new URL(
        process.env.VUE_APP_URL_SEARCHBOOSTER
          ? process.env.VUE_APP_URL_SEARCHBOOSTER
          : "https://api4.searchbooster.io/api/625f4bc3-08ae-46ce-a80e-286fddb6a239/completions",
      )
      url.searchParams.set("query", payload)
      return fetch(url)
        .then((response) => {
          return response.json()
        })
        .then((data) => {
          return data.searchBox
        })
    },
  },
}
