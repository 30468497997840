import axios from "axios"
import router from "../router"
import store from "../store"
import errorMessages from "~/utils/errorMessages"
import { menuItem } from "../router/menu"

export default {
  clear_user({ commit }) {
    commit("LOGOUT")
    router.push("/login")
  },

  async log_in_from_cache({ commit }, token) {
    axios.defaults.headers.common["Authorization"] = "Bearer " + token
    return await new Promise((resolve) => {
      axios.post("/api/panel/users/startadmin").then((response) => {
        if (response.data.error) {
          const errorCode = response.data.code || response.data.message || response.data.error
          // Critical ERRORS
          if (["ACCESS_ERROR", 1401].includes(errorCode)) {
            store.commit("SET_ERROR_MESSAGE", errorMessages(errorCode))
            store.dispatch("clear_user")
          }
          resolve(false)
          return
        }

        let data = {
          token,
          user: response.data.user,
        }

        let step = null
        if (response.data.merchant && response.data.merchant.length) {
          data.merchant = response.data.merchant
          step = response.data.merchant[0].step
        }
        let payl = {
          appeals_count: response.data?.appeals_count || 0,
          orders_count: response.data?.orders_count || 0,
          bugs_count: response.data?.bugs_count || 0,
        }

        const { role_id, id } = response.data.user

        commit("SET_NOTICES", payl)
        commit("SET_USER", data)
        commit("SET_MENU", menuItem({ role_id, user_id: id }, step))
        resolve(response.data)
      })
    })
  },

  async SIGNIN({ commit }, payload) {
    return await new Promise((resolve) => {
      axios.post("/api/panel/users/signinpanel", payload).then((response) => {
        if (!response.data || response.data.error) {
          // const errorCode = response.data?.code || response.data?.message || response.data?.error

          // store.commit("SET_ERROR_MESSAGE", errorMessages(errorCode))
          // // Critical ERRORS
          // if (["ACCESS_ERROR", 1401].includes(errorCode)) {
          //   store.dispatch("clear_user")
          // }

          resolve(false)
          return
        }

        const token = response.data.access_token
        axios.defaults.headers.common["Authorization"] = "Bearer " + token
        let data = {
          token,
          user: response.data.user,
        }

        let step = null

        if (response.data.merchant && response.data.merchant.length) {
          data.merchant = response.data.merchant
          step = response.data.merchant[0].step
          if (response.data.merchant.step) {
            router.push("/merchants-registration")
          }
        }
        let payl = {
          appeals_count: response.data?.appeals_count || 0,
          orders_count: response.data?.orders_count || 0,
          bugs_count: response.data?.bugs_count || 0,
        }

        const { role_id, id } = response.data.user

        commit("SET_NOTICES", payl)
        commit("SET_USER", data)
        commit("SET_MENU", menuItem({ role_id, user_id: id }, step))
        resolve(response)
      })
    })
  },

  REFRESH_MENU({ commit }, payload) {
    const { role_id, id, step } = payload
    commit("SET_MENU", menuItem({ role_id, user_id: id }, step))
  },

  uploadImage(ctx, data) {
    const config = { "content-type": "multipart/form-data" }
    const formData = new FormData()
    formData.append("photo", data.photo)
    if (data.id) {
      formData.append("id", data.id)
    }
    return new Promise((resolve) => {
      axios
        .post(`/api/${data.type}/image`, formData, config)
        .then((res) => {
          resolve(res.data)
        })
        .catch((error) => ({ error: JSON.stringify(error) }))
    })
  },

  updateImageParams(ctx, data) {
    return new Promise((resolve) => {
      axios
        .put(`/api/panel/image/${data.id}`, data)
        .then((res) => {
          resolve(res.data)
        })
        .catch((error) => ({ error: JSON.stringify(error) }))
    })
  },

  updateMasterImageSort(ctx, data) {
    return new Promise((resolve) => {
      axios
        .put(`/api/panel/masters/updateImageSort`, data)
        .then((res) => {
          resolve(res.data)
        })
        .catch((error) => ({ error: JSON.stringify(error) }))
    })
  },

  updateProductImageSort(ctx, data) {
    return new Promise((resolve) => {
      axios
        .put(`/api/product/updateImageSort`, data)
        .then((res) => {
          resolve(res.data)
        })
        .catch((error) => ({ error: JSON.stringify(error) }))
    })
  },

  removeImage(ctx, data) {
    return new Promise((resolve) => {
      axios
        .post(`/api/panel/removeImage/${data.id}`, data)
        .then((res) => {
          resolve(res.data)
        })
        .catch((error) => ({ error: JSON.stringify(error) }))
    })
  },

  getCountryList({ commit }) {
    axios
      .get("/api/getCountryList")
      .then((res) => {
        commit("SET_COUNTRY", res.data.countryList)
      })
      .catch((error) => ({ error: JSON.stringify(error) }))
  },

  getCurrencyList({ commit }) {
    axios
      .get("/api/getCurrencyList")
      .then((res) => {
        commit("SET_CURRENCY", res.data.currencyList)
      })
      .catch((error) => ({ error: JSON.stringify(error) }))
  },

  checkYtb(ctx, url) {
    return new Promise((resolve) => {
      axios
        .get(`https://www.youtube.com/oembed?url=${url}&format=json`)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => ({ error: JSON.stringify(error) }))
    })
  },

  setMiniVariant: ({ commit }) => {
    commit("SET_MIN_SIDEBAR")
  },
}
