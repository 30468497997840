import Vue from "vue"
import Vuetify from "vuetify/lib/framework"
import ru from "vuetify/es5/locale/ru"
import "@mdi/font/css/materialdesignicons.min.css"

Vue.use(Vuetify)

export default new Vuetify({
  rtl: false,
  lang: {
    locales: { ru },
    current: "ru",
  },
  icons: {
    iconfont: "mdi",
  },
  theme: {
    dark: false,
    default: "light",
    disable: false,
    options: {
      cspNonce: undefined,
      customProperties: true,
      minifyTheme: undefined,
      themeCache: undefined,
    },
    themes: {
      light: {
        primary: "#0097a7",
        secondary: "#8691ca",
        accent: "#7C4DFF",
        error: "#EF5350",
        info: "#42A5F5",
        success: "#16ab58",
        warning: "#FF9800",
        background: "#F2F3F7",
        cancel: "#8aa2ae",
        dev: "#196985",

        rating: "#FFA000",
        cardBackground: "#F1F1F1",
        textFieldBackground: "#ffffff",
        neu: {
          darken1: "#DDE4EF",
          lighten1: "#FFFFFF",
        },
      },
      dark: {
        primary: "#7C4DFF",
        secondary: "#5161b3",
        accent: "#0097a7",
        error: "#EF5350",
        info: "#42A5F5",
        success: "#16ab58",
        warning: "#FFA000",
        background: "#171717",
        cancel: "#8aa2ae",

        dev: "#272727 ",

        rating: "#FFA000",
        cardBackground: "#F1F1F1",
        textFieldBackground: "#ffffff",
        neu: {
          darken1: "#171717",
          lighten1: "#171717",
        },
      },
      primaryBgText: "#ffffff",
      secondaryBgText: "#ffffff",
    },
  },
})
