<template>
  <v-text-field
    :value="value"
    clearable
    :append-icon="!value ? 'mdi-magnify' : ''"
    label="Быстрый поиск"
    single-line
    hide-details
    filled
    dense
    class="width-300"
    @input="(value) => $emit('input', value)"
  />
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: null,
    },
  },
}
</script>
