//  Закоменти adward чтобы проект ломился к боевой базе
//let URL_BASE = "https://back2.bh.market"
let URL_BASE = "https://back.bh.market"

let URL_FRONTEND = "http://localhost:4003"
let URL_DASHBOARD = "http://localhost:3000"
let URL_MULTISEARCH = "https://api.multisearch.io/?id=11577"
let URL_SEARCHBOOSTER =
  "https://api4.searchbooster.io/api/625f4bc3-08ae-46ce-a80e-286fddb6a239/completions"

const host = document.location.host

switch (host) {
  case "admindev.bh.market":
    URL_FRONTEND = "https://frontdev.bh.market"
    URL_DASHBOARD = "https://admindev.bh.market"
    break
  case "adminlocal.bh.market":
    URL_BASE = "http://localhost:8081"
    URL_FRONTEND = "https://frontlocal.bh.market"
    URL_DASHBOARD = "https://adminlocal.bh.market"
    break
  case "admin.bh.market":
    URL_BASE = "https://back.bh.market"
    URL_FRONTEND = "https://bh.market"
    URL_DASHBOARD = "https://admin.bh.market"
    break
}

export { URL_BASE, URL_FRONTEND, URL_DASHBOARD, URL_MULTISEARCH, URL_SEARCHBOOSTER }
