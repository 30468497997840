import axios from "axios"
export default {
  state: {},
  mutations: {},
  actions: {
    getAdressByParam(_, address) {
      const params = new URLSearchParams({ address })
      return axios
        .get(`/api/dadata/getAddresses`, { params })
        .then((response) => {
          return response.data
        })
        .catch((error) => ({ error: JSON.stringify(error) }))
    },
    getStreetByParam(_, payload) {
      const params = new URLSearchParams(payload)
      return axios
        .get(`/api/dadata/getAddressOfCity`, { params })
        .then((response) => {
          return response.data
        })
        .catch((error) => ({ error: JSON.stringify(error) }))
    },
    getDelivery(_, kladr_id) {
      return axios
        .get(`/api/dadata/getDelivery/${kladr_id}`)
        .then((response) => {
          return response.data
        })
        .catch((error) => ({ error: JSON.stringify(error) }))
    },
    addStock(_, data) {
      return axios
        .post("/api/merchant/addStock", data)
        .then((response) => {
          return response.data
        })
        .catch((error) => ({ error: JSON.stringify(error) }))
    },
    deleteStock(_, id) {
      return axios
        .delete(`/api/merchant/deleteStock/${id}`)
        .then((response) => {
          return response.data
        })
        .catch((error) => ({ error: JSON.stringify(error) }))
    },
  },
}
