import axios from "axios"
export default {
  state: {
    list: [],
    _formSelect: [
      { id: -1, name: "Незаполненный бренд" },
      { id: 98, name: "БЕЗ БРЕНДА" },
    ],
  },
  mutations: {
    SET_BRANDS(state, payload) {
      state.list = payload
    },
  },
  actions: {
    addBrand(ctx, payload) {
      return new Promise((resolve) => {
        axios.post("/api/panel/brand/add", payload).then((response) => {
          resolve(response.data)
        })
      })
    },

    getBrands({ commit }) {
      return new Promise((resolve) => {
        axios.get("/api/panel/brand/listShort?main=true").then((response) => {
          commit(
            "SET_BRANDS",
            response.data.brandList.sort((a, b) => (a.name > b.name && 1) || -1),
          )
          resolve(response)
        })
      })
    },

    getBrandsFull(ctx, data) {
      return new Promise((resolve) => {
        axios.post("/api/panel/brand/list", { perPage: 30, ...data }).then((response) => {
          resolve(response.data.brandList)
        })
      })
    },

    getBrand(ctx, payload) {
      return new Promise((resolve) => {
        axios.get(`/api/panel/brand/${payload}`).then((response) => {
          resolve(response.data)
        })
      })
    },

    updateBrand(ctx, payload) {
      return new Promise((resolve) => {
        axios.put(`/api/panel/brand/${payload.id}`, payload).then((response) => {
          resolve(response.data)
        })
      })
    },

    removeBrand(ctx, data) {
      return new Promise((resolve) => {
        axios.delete(`/api/panel/brand/${data.id}?comment=${data.comment}`).then((response) => {
          resolve(response.data)
        })
      })
    },

    addContry(ctx, payload) {
      return new Promise((resolve) => {
        axios.post(`/api/panel/brand/country/add`, payload).then((response) => {
          resolve(response.data)
        })
      })
    },

    removeCountry(ctx, payload) {
      return new Promise((resolve) => {
        axios.post(`/api/panel/brand/country/delete`, payload).then((response) => {
          resolve(response.data)
        })
      })
    },
  },
  getters: {
    brandsList: (state) => {
      return [...state._formSelect, ...state.list]
    },
  },
}
