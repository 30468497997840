import axios from "axios"
export default {
  state: {
    list: [],
  },
  mutations: {
    SET_APPEAL_LIST(state, data) {
      state.list = data
    },
    CHANGE_APPEAL_LIST(state, data) {
      let appeal = state.list.find((i) => i.id == data.id)
      if (appeal) {
        appeal.status = data.status
      }
    },
  },
  actions: {
    get({ commit }, data) {
      axios.post("/api/appeal/list", data).then((response) => {
        commit("SET_APPEAL_LIST", response.data.appeals)
      })
    },

    change({ commit }, data) {
      axios.put(`/api/appeal/${data.id}`, data).then((response) => {
        commit("CHANGE_APPEAL_LIST", response.data.appeal)
      })
    },

    remove({ commit }, id) {
      axios.delete(`/api/appeal/${id}`).then((response) => {
        commit("SET_APPEAL_LIST", response.data.appeals)
      })
    },
  },
}
