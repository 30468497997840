import axios from "axios"
export default {
  state: {
    list: [],
  },
  mutations: {
    SET_LIST(state, payload) {
      state.list = payload
    },
  },
  actions: {
    parserList({ commit }) {
      axios.get("/api/panel/parser/listParserOptions").then((response) => {
        commit("SET_LIST", response.data.data)
      })
    },
    getParser(_, payload) {
      const params = new URLSearchParams(payload)
      return axios.get("/api/panel/parser/getParserOptions", { params }).then((response) => {
        return response.data
      })
    },
    addParser(_, payload) {
      return axios.post("/api/panel/parser/addParserOptions", payload).then((response) => {
        return response.data
      })
    },
    editParser(_, payload) {
      return axios.post("/api/panel/parser/editParserOptions", payload).then((response) => {
        return response.data
      })
    },
    deleteParser(_, payload) {
      return axios.post("/api/panel/parser/deleteParserOptions", payload).then((response) => {
        return response.data
      })
    },
  },
  getters: {
    parserList: (state) => {
      return state.list
    },
  },
}
