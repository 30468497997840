import { VDialog } from "!/node_modules/vuetify/lib"
import "./VRightDialog.sass"

export default VDialog.extend({
  name: "v-right-dialog",

  props: {
    scrollable: {
      type: Boolean,
      default: true,
    },
    maxWidth: {
      type: [String, Number],
      default: "320",
    },
    transition: {
      type: String,
      default: "right-dialog-transition",
    },
    persistent: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    classes() {
      return {
        ...VDialog.options.computed.classes.call(this),
        "v-right-dialog-transition": true,
      }
    },
  },
})
