import axios from "axios"
export default {
  state: {
    groupProductList: [],
  },
  mutations: {
    SET_LIST(state, payload) {
      state.groupProductList = payload
    },
  },
  actions: {
    addGroupProducts(_, payload) {
      return axios
        .post(`/api/panel/groupedProducts/add`, payload)
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    getGroupProducts() {
      return axios
        .get("/api/panel/groupedProducts/all")
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    getGroupProductsWithPagination({ commit }, payload) {
      return axios
        .post("/api/panel/groupedProducts/getProducts", payload)
        .then((response) => {
          commit("SET_LIST", response.data.groupedProducts)
          return response.data
        })
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    getGroupProductsStat(_, payload) {
      return axios
        .post("/api/panel/groupedProducts/getProductsCountAndOffersCount", payload)
        .then((res) => {
          return res.data
        })
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    getGroupProductsById(_, { id }) {
      return axios
        .get(`/api/panel/groupedProducts/${id}`)
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    updateGroupProduct(_, payload) {
      return axios
        .post(`/api/panel/groupedProducts/updateGroupedProduct`, payload)
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    uploadGroupProductImage(_, data) {
      const config = { "content-type": "multipart/form-data" }
      const formData = new FormData()
      formData.append("photo", data.photo)
      if (data.id) {
        formData.append("id", data.id)
      }
      return axios
        .post("/api/panel/groupedProducts/image", formData, config)
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    addFileGroupProduct(_, data) {
      return axios
        .post("/api/panel/groupedProducts/files", data)
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    renameFileGroupProduct(_, data) {
      return axios
        .post("/api/panel/groupedProducts/file/rename", data)
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    deleteFileGroupProduct(_, data) {
      return axios
        .post("/api/panel/groupedProducts/file/delete", data)
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    ungroupGroupProduct(_, { id }) {
      return axios
        .put(`/api/panel/groupedProducts/ungroup/${id}`)
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    updateImageSortGroupProduct(_, data) {
      return axios
        .post(`/api/panel/groupedProducts/updateImageSort`, data)
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    setMainImageGroupProduct(_, id) {
      return axios
        .post(`/api/panel/groupedProducts/setMainImage/${id}`)
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    deleteGroupProduct(_, data) {
      return axios
        .post(`/api/panel/groupedProducts/deleteGroupedProduct`, data)
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    unlinkMasterProductFromGroupedGroup(_, data) {
      return axios
        .post(`/api/panel/groupedProducts/unlinkMpFromGp`, data)
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    changeActiveGroupProduct(_, { id, comment }) {
      return axios
        .get(`/api/panel/groupedProducts/switchGroupedProduct/${id}?comment=${comment}`)
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    addToGroupedProduct(_, payload) {
      return axios
        .post(`/api/panel/groupedProducts/addToGroupedProduct`, payload)
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    getMasterProuctByGroupProductId(_, { id }) {
      return axios
        .get(`/api/panel/masters/getMpByGroupId/${id}`)
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    changeGroupProductsCategories(_, payload) {
      return axios
        .post(`/api/panel/groupedProducts/changeCategories`, payload)
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    changeSelectGroupFillingStatus(_, data) {
      return axios
        .post("/api/panel/groupedProducts/setCheckDataGroupedProducts", data)
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    getOptionsOfMps(_, { id }) {
      return axios
        .get(`/api/panel/groupedProducts/getOptionsOfMps/${id}`)
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    addOption(_, payload) {
      return axios
        .post(`/api/panel/groupedProducts/addGpOption`, payload)
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    deleteOption(_, { id }) {
      return axios
        .delete(`/api/panel/groupedProducts/GpOption/${id}`)
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    getMasterProductImagesByGroupProductId(_, payload) {
      const params = new URLSearchParams(payload)
      return axios
        .get(`/api/panel/masters/getMasterProductImagesByGroupProductId`, { params })
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    copyMasterProductImagesToGroupedProduct(_, payload) {
      return axios
        .post(`/api/panel/masters/copyMasterProductImagesToGroupedProduct`, payload)
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },
  },
  getters: {
    groupProductList: (state) => {
      return state.groupProductList
    },
  },
}
