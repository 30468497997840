import axios from "axios"
export default {
  state: {
    statusList: [],
    deliveryList: [],
    _status: {
      created: { id: 1, text: "Создан" },
      inWork: { id: 2, text: "В работе" },
      reserved: { id: 3, text: "Зарезервирован" },
      billed: { id: 4, text: "Cчет выставлен" },
      billPaid: { id: 7, text: "Cчет оплачен" },
      paymentReceived: { id: 8, text: "Оплата получена" },
      deliveryService: { id: 9, text: "Передан в службу доставки" },
      goodsReceived: { id: 10, text: "Получен" },
      сanceledBySeller: { id: 11, text: "Отменен продавцом" },
      canceledByBuyer: { id: 12, text: "Отменен покупателем" },
      changedBySeller: { id: 13, text: "Изменен продавцом" },
      confirmedByBuyer: { id: 14, text: "Подтвержден покупателем" },
      modifiedByAdmin: { id: 15, text: "Изменен администратором" },
      canceledByAdmin: { id: 17, text: "Отменен администратором" },

      notProcessed: { id: 16, text: "Не обработан" },
      successfully: { id: 20, text: "Успешно реализовано" },
      closed: { id: 21, text: "Закрыто и не реализовано" },
    },
    _allowedMerchantStatus: [3, 4, 8, 9, 11],
    statusNotification: {
      wait: { id: 1, text: "Уведомление ожидает отправки" },
      success: { id: 2, text: "Уведомление отправлено" },
    },
  },
  mutations: {
    SET_STATUS_LIST(state, payload) {
      state.statusList = payload
    },
    SET_DELIVERY_LIST(state, payload) {
      state.deliveryList = payload
    },
  },
  actions: {
    addOrder(_, payload) {
      return new Promise((resolve) => {
        axios.post("/api/panel/order/add", payload).then((response) => {
          resolve(response.data)
        })
      })
    },

    list({ rootState }, payload) {
      if ([2, 5].includes(rootState.user.role_id)) {
        return axios
          .post("/api/panel/order/list", payload)
          .then((response) => response.data)
          .catch((error) => ({ error: JSON.stringify(error) }))
      } else {
        const params = new URLSearchParams(payload)
        return axios
          .get("/api/user/order/listOfMerchant", { params })
          .then((response) => response.data)
          .catch((error) => ({ error: JSON.stringify(error) }))
      }
    },

    getOrder(_, payload) {
      return new Promise((resolve) => {
        axios.get(`/api/order/getById/${payload}`).then((response) => {
          resolve(response.data)
        })
      })
    },

    // updateOrder(_, payload) {
    //   return new Promise((resolve) => {
    //     axios
    //       .post(`/api/order/updateById/${payload.id}`, payload)
    //       .then((response) => {
    //         resolve(response.data)
    //       })
    //   })
    // },

    cancelOrder(_, payload) {
      return new Promise((resolve) => {
        axios.post(`/api/order/cancelById/${payload.id}`, payload).then((response) => {
          resolve(response.data)
        })
      })
    },

    removeOrder(_, { id, password }) {
      return axios
        .post(`/api/panel/order/delete/${id}`, { password })
        .then((response) => {
          return response.data
        })
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    getOrderStatus(_, id) {
      return new Promise((resolve) => {
        axios.get(`/api/order/orderStatuses/get/${id}`).then((response) => {
          resolve(response.data)
        })
      })
    },

    getWrapper(_, id) {
      return new Promise((resolve) => {
        axios.get(`/api/order/getWrapper/${id}`).then((response) => {
          resolve(response.data)
        })
      })
    },

    getStatusList({ rootState, commit }) {
      axios.get(`api/order/statuses/listall`).then((response) => {
        let list = response.data.statuses
        if (rootState.user.role_id == 4 && rootState.user.merchant_id == 1) {
          list.map((i) => {
            return i.id == 3 ? (i.name = "Товар на сборке") : i
          })
        }
        commit("SET_STATUS_LIST", list)
      })
    },

    getDeliveryList({ commit }) {
      axios.get(`api/panel/deliveryBook/get?all=1`).then((response) => {
        commit("SET_DELIVERY_LIST", response.data.data)
      })
    },

    updateStatusOrder(_, data) {
      return new Promise((resolve) => {
        axios.post(`api/order/orderStatuses/add`, data).then((response) => {
          resolve(response.data)
        })
      })
    },

    addProductOrder(_, data) {
      return new Promise((resolve) => {
        axios.post(`api/order/add`, data).then((response) => {
          resolve(response.data)
        })
      })
    },

    editOrder(_, data) {
      return axios
        .post(`api/order/edit`, data)
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    editAdminOrderInfo(_, payload) {
      return axios
        .post(`/api/panel/order/editByAdmin/${payload.id}`, payload)
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    createInvoice(_, payload) {
      return axios
        .post(`/api/panel/order/statuses/createInvoice`, payload)
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    confirmOrder(_, data) {
      return axios
        .post(`api/order/statuses/add`, data)
        .then((response) => {
          return response.data
        })
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    report(_, data) {
      return axios
        .post(`api/panel/order/list-report`, data)
        .then((response) => {
          return response.data
        })
        .catch((error) => ({ error: JSON.stringify(error) }))
    },
  },
  getters: {
    statusOrderList: (state) => {
      return state._status
    },
    statusList: (state) => {
      return state.statusList
    },
    statusNotificationList: (state) => {
      return state.statusNotification
    },
    allowedMerchantStatus: (state) => {
      return state._allowedMerchantStatus
    },
  },
}
