export default {
  isSuperAdmin: (state) => {
    // return (
    //   state.user &&
    //   [42, 3, 25].includes(state.user.id) &&
    //   state.user.role_id === state.users.users._role.admin.id
    // )
    return state.user && state.user.role_id === state.users.users._role.admin.id
  },
  isAdmin: (state) => {
    return state.user && state.user.role_id === state.users.users._role.admin.id
  },
  isMerchant: (state) => {
    return state.user && state.user.role_id === state.users.users._role.merchant.id
  },
  isManager: (state) => {
    return state.user && state.user.role_id === state.users.users._role.manager.id
  },
  isHighManager: (state) => {
    return (
      state.user &&
      [105].includes(state.user.id) &&
      state.user.role_id === state.users.users._role.manager.id
    )
  },
}
