import Vue from "vue"

import {
  Image,
  ImageUpload,
  ButtonActionLarge,
  CommentDialogs,
  VTreeviewExtended,
  TooltipText,
} from "~~/UI"

Vue.component("VImage", Image)
Vue.component("VImageUpload", ImageUpload)
Vue.component("VButtonActionLarge", ButtonActionLarge)
Vue.component("CommentDialogs", CommentDialogs)
Vue.component("VTreeviewExtended", VTreeviewExtended)
Vue.component("TooltipText", TooltipText)

/*
Object.entries(components).forEach(([name, component]) => {
  Vue.component(name, component)
}) */
