import axios from "axios"
const config = { "content-type": "multipart/form-data" }

export default {
  state: {},
  mutations: {},
  getters: {},
  actions: {
    getBugsById(_, payload) {
      const params = new URLSearchParams(payload)
      return axios
        .get("/api/panel/masterProductBugs/getByMasterProductId", { params })
        .then((response) => {
          return response.data
        })
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    addBugs(_, payload) {
      return axios
        .post("/api/panel/masterProductBugs/add", payload, config)
        .then((response) => {
          return response.data
        })
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    deleteBugs(_, payload) {
      return axios
        .post("/api/panel/masterProductBugs/delete", payload)
        .then((response) => {
          return response.data
        })
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    editBugs(_, payload) {
      return axios
        .post("/api/panel/masterProductBugs/edit", payload, config)
        .then((response) => {
          return response.data
        })
        .catch((error) => ({ error: JSON.stringify(error) }))
    },
  },
}
