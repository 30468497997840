<template>
  <v-card class="confirm-box">
    <v-card-title class="pb-0">
      <span class="margin-auto">{{ title }}</span>
    </v-card-title>
    <v-card-text class="text-center py-2">{{ subtitle }}</v-card-text>
    <v-card-text class="py-0">
      <v-row>
        <v-col v-if="leftBtnTxt" cols>
          <v-btn block text @click.stop="leftBtnEvent">{{ leftBtnTxt }}</v-btn>
        </v-col>
        <v-col v-if="rightBtnTxt" cols>
          <v-btn block text color="error" @click.stop="rightBtnEvent">{{ rightBtnTxt }}</v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  props: {
    title: { type: String, default: "" },
    subtitle: { type: String, default: "" },
    leftBtnTxt: { type: String, default: "" },
    rightBtnTxt: { type: String, default: "" },
    leftBtnEvent: { type: Function, default: () => {} },
    rightBtnEvent: { type: Function, default: () => {} },
  },
}
</script>
