import axios from "axios"
import { deepFreeze } from "~/utils/helpers"

const _placement = deepFreeze({
  1: {
    value: 50,
    text: "заказов",
  },
  2: {
    value: 90,
    text: "дней",
  },
})

export default {
  state: {
    list: [],
    listAll: [],
    merchant: [],
    _finalMerchantStep: 8,
    _stepList: [
      {
        step: 1,
        route: "requisites",
        name: "Регистрационные данные",
      },
      {
        step: 2,
        route: "about",
        name: "О компании",
      },
      {
        step: 5,
        route: "loading",
        name: "Загрузка товаров",
      },
      {
        step: 6,
        route: "storage",
        name: "Склады",
      },
      {
        step: 7,
        route: "delivery",
        name: "Службы доставки",
      },
      {
        route: "member",
        name: "Представители компании",
      },
      {
        step: 3,
        route: "placement",
        name: "Условия размещения",
      },
      {
        step: 4,
        route: "offer",
        name: "Оферта и документы",
      },
      {
        route: "managers",
        name: "Менеджеры",
      },
      {
        route: "notifications",
        name: "Уведомления",
      },
      {
        route: "import",
        name: "Настройки импорта",
      },
      {
        route: "customization",
        name: "Общие настройки",
      },
      {
        route: "analytics",
        name: "Аналитика",
      },
    ],
    _placement,
  },
  mutations: {
    SET_MERCHANTS(state, payload) {
      state.list = payload.sort((a, b) => (a.name > b.name && 1) || -1)
    },
    SET_MERCHANTS_ALL(state, payload) {
      state.listAll = payload
        .sort((a, b) => (a.name > b.name && 1) || -1)
        .map(({ id, name }) => ({ id, name }))
    },
  },

  actions: {
    addMerchant(_, payload) {
      return axios
        .post("/api/merchant/add", payload)
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    getMerchantList({ commit }, payload) {
      return axios
        .post("/api/admin/merchant/list", {
          ...payload,
          perPage: 1000,
        })
        .then((response) => {
          commit("SET_MERCHANTS", [...response.data.merchantList.data])
          return response.data.merchantList
        })
    },

    getMerchantListAll({ commit }) {
      return axios
        .post("/api/admin/merchant/list", {
          perPage: 1000,
        })
        .then((response) => {
          commit("SET_MERCHANTS_ALL", response.data.merchantList.data)
          return response.data.merchantList
        })
    },

    getMerchant(_, payload) {
      return axios
        .get(`/api/merchant/${payload}`)
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    getMerchantDeliveryCost(_, payload) {
      return axios
        .post("/api/getMerchantDeliveryCost", payload)
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    updateMerchantDeliveryCost(_, payload) {
      return axios
        .post("/api/updateMerchantDeliveryCost", payload)
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    addMerchantDeliveryCost(_, payload) {
      return axios
        .post("/api/addMerchantDeliveryCost", payload)
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    deleteMerchantDeliveryCost(_, id) {
      return axios
        .delete(`/api/deleteMerchantDeliveryCost/${id}`)
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    updateMerchant(_, payload) {
      return axios
        .put(`/api/merchant/${payload.id}`, payload)
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    removeMerchant(_, data) {
      return axios
        .delete(`/api/merchant/${data.id}?comment=${data.comment}`)
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    getUserRoleMerchant() {
      return axios
        .get("/api/user_merchants")
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    getMerchantUsersById(_, id) {
      return axios
        .get(`/api/merchant/userList/${id}`)
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    addUserToMerchantById(_, payload) {
      return axios
        .post("/api/merchant/addUser", payload)
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    delMerchantUserById(_, payload) {
      return axios
        .post("/api/merchant/delUser", payload)
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    async getCompanyByParam(_, payload) {
      return await axios
        .get(`/api/dadata/getCompanyByParam?param=${payload}`)
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    async getBankByBic(_, payload) {
      return await axios
        .post(`/api/getBankByBic`, payload)
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    frozeMerchantStatus(_, data) {
      return axios
        .post(`/api/panel/merchant/froze/${data.id}?comment=${data.comment}`)
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    unfrozeMerchantStatus(_, data) {
      return axios
        .post(`/api/panel/merchant/unFroze/${data.id}?comment=${data.comment}`)
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    /**
     * Registration methods
     */

    getMerchantRegistration(_, payload) {
      return axios
        .post(`/api/panel/merchant/getRegData`, payload)
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    updateMerchantRegistration(_, payload) {
      let data = payload.information ?? payload.data
      return axios
        .post(`/api/panel/merchant/add/${payload.step}`, data)
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },

    setConfirmed(_, payload) {
      return axios
        .post(`/api/panel/merchant/setConfirmed`, payload)
        .then((response) => response.data)
        .catch((error) => ({ error: JSON.stringify(error) }))
    },
  },
  getters: {
    getMerchantNameById: (state) => (id) => {
      let merch = state.list.length ? state.list.find((cat) => cat.id === id) : false
      return merch ? merch.name : false
    },
    getCurrentStepState: (state) => (id) => {
      if (id == state._finalMerchantStep) return "Завершил регистрацию"

      let step = state._stepList.find((one) => one.step === id)
      return step ? step.name : "Шаг не определен"
    },
    getMerchants: (state) => {
      return state.list
    },
    getMerchantsList: (state) => {
      return state.listAll
    },
    getMerchantData: (state) => {
      return state
    },
    getMerchantFrozenStatusById: (state) => (id) => {
      const merchant = state.list.find((one) => one.id === id)
      if (merchant) return merchant.frozen

      return null
    },
  },
}
